.profile0 {
  color: #000000 !important;
  z-index: 2;
  h3,
  h6 {
    font-size: 1rem;
    color: #000000;
    font-weight: 500;
  }
  a {
    font-size: 14px;
  }
  p {
    color: #000;
  }
  .hosp0 {
    font-size: 0.9rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .hosAddress {
    @media only screen and (max-width: 990px) {
      max-width: 100% !important;
    }
    max-width: 300px;
    margin-top: 0.5rem;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
    span {
      display: block;
    }
  }
  a {
    font-weight: 500 !important;
  }
  .viewMap {
    padding-left: 2px;
  }
  .map0 {
    vertical-align: middle;
    color: #286ce2;
    margin-left: -5px;
    font-size: 10px;
    margin-right: 5px;
  }
  .viewProfile {
    margin-top: 1rem;
  }
}
