.self-checker-certificate {
  padding: 1rem 0rem;

  @media only screen and (max-width: 768px) {
    .pf0 {
      padding: 0px 0px !important;
    }
    .certBody {
      padding: 0rem 0rem !important;
    }
    .ch-row {
      padding-left: 0px !important;
    }
  }
  .timeRight {
    float: right !important;
    font-size: 14px;
  }
  section {
    padding: 0px;
    padding: 0px;
    max-height: 100% !important;
    overflow-y: auto;
    overflow-x: auto;
  }
  .p00 {
    margin-bottom: 0px;
    margin-top: 5px !important;
    font-size: 14px;
    letter-spacing: 0.28px;
    text-align: left;
    font-weight: 500;
  }
  .pf0 {
    margin-top: 2rem;
    font-size: 1rem !important;
    margin-bottom: 2rem;
    h3 {
      font-size: 16px;
      font-weight: 600;
    }
    p {
      margin-bottom: 5px;
    }
  }
  .certBody {
    padding: 0rem 45px;
    p {
      font-size: 1rem !important;
      color: #000;
    }
  }
  p {
    text-align: left !important;
  }
  .hh0 {
    font-weight: 500;
    margin-bottom: 3px;
  }
  .h-status {
    font-weight: 500;
    margin-top: 0rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: bold;
    span {
      font-size: 24px;
      vertical-align: middle;
      padding-right: 8px;
    }
  }
  .h-home {
    color: #f5a01a;
    font-size: 1rem;
  }
  .h-work {
    color: #55ba47;
    font-size: 1rem;
  }
  .h-er {
    color: #ed1c24;
    font-size: 1rem;
  }
  section {
    overflow: hidden !important;
  }

  .cta {
    text-align: right;
    margin-top: 0rem;
    font-size: 1.3rem;
    cursor: pointer;
    .ctaprint {
      color: #1961c5;
      text-align: center;
    }
  }
}
