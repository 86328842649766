.rewardsWidget {
  margin-bottom: 20px;
  .rewards-balance {
    background-color: #00a262;
  }
  .current-points {
    background-color: #005dac;
  }
  h3 {
    color: #fff;
    font-weight: 300;
    font-size: 2.7rem;
    margin-bottom: 0;
  }
  .widget-section {
    min-height: 106px;
  }
  .widget-section > h3 {
    font-size: 0.95rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 5px 0px 10px;
    margin-bottom: 0;
  }
  .widget-section div {
    margin-left: 72px;
    margin-top: -8px;
  }
  .card-icon {
    margin-right: 8px;
  }
}

.rewards-browse-redeem-button {
  right: auto !important;
  left: 1rem;
  margin-bottom: 0 !important;
}
