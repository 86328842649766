//colors
$color_endeavour_approx: #005dac;
$color_cerulean_approx: #009ee1;
$color_spindle_approx: #abd3f7;

.spinner {
  animation: rotator 1.4s linear infinite;
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}
@keyframes colors {
  0% {
    stroke: $color_endeavour_approx;
  }
  25% {
    stroke: $color_endeavour_approx;
  }
  50% {
    stroke: $color_cerulean_approx;
  }
  75% {
    stroke: $color_cerulean_approx;
  }
  100% {
    stroke: $color_spindle_approx;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
