.cost-estimator-hero {
  a {
    text-decoration: underline;
    color: #005ead;
  }
  @media only screen and (max-width: 990px) {
    padding: 2.5rem 0rem;
  }
  @media only screen and (min-width: 990px) and (max-width: 1144px) {
    padding: 1.5rem 0.2rem;
  }
  padding: 1rem 1rem;
  background-repeat: no-repeat;
  margin-bottom: 0.1rem;
  box-shadow: 0px 1px 0px 0px #ccc;
  h3 {
    margin-top: 0rem;
    font-weight: 300;
    color: #083863;
    text-shadow: 1px 0px 0px #d7d7d7;
    text-align: center;
    font-size: 1.2rem;
  }
  //
  .note {
    margin-bottom: 1.5rem;
  }
}
