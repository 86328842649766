@import '../variables';

.container {
  padding: 15px 15px;

  .row {
    &:last-child {
      .column {
        text-align: center;
      }
    }

    .columns {
      &.description {
        font-size: 0.8rem;
        padding-bottom: 15px;

        span {
          color: $requiredColor;
        }
      }

      &.form-group {
        margin-bottom: 0.2rem;

        label {
          margin-bottom: 0rem;
          font-size: 0.8rem;

          &.required {
            &:after {
              content: ' *';
              color: $requiredColor;
            }
          }
        }

        input {
          margin-bottom: 5px;
        }

        .error {
          font-size: 0.7rem;
          line-height: 0.7rem;
          color: #ff0000;
        }
      }
      &.start-button {
        text-align: center;

        button {
          @extend %buttonStyle;
          margin: 5px 0px;
        }
      }
    }
  }

  .company-name {
    padding-top: 5px;
  }
}
