//@import '../custom.scss';

.cost-estimator-search {
  background: #fff;
  width: auto;
  color: #000;
  padding: 1rem 2rem;

  @media only screen and (max-width: 990px) {
    padding: 0rem 0rem;
    .hep1 {
      display: flex;
      margin-top: 10px;
      align-items: center;
    }
    .btn1 {
      margin: 1rem auto 0px auto !important;
    }
    .seach-col {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
    label.search-for.form-label {
      padding-left: 15px !important;
    }
    .btn-lg {
      border-radius: 50% !important;
      padding: 0.5rem 0.5rem !important;
      height: 40px;
      font-size: 16px;
      width: auto;
      min-width: 40px !important;
      min-height: 40px !important;
    }
    .form-control {
      height: calc(1em + 2rem + 2px) !important;
      padding-left: 0px !important;
    }
    .hep0 {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-left: solid 0px #ccc !important;
      border-right: solid 0px #ccc !important;
    }
    .btn-dk {
      display: none !important;
    }
    .error-text {
      margin-top: 0.3rem !important;
      margin-left: 0px !important;
    }
    .select {
      margin: 0px -15px !important;
    }
    .clear-zip {
      bottom: 10px !important;
    }
    #searchText {
      font-size: 12px;
      a {
        overflow: visible !important;
      }
      .dropdown-item {
        display: block;
        white-space: normal !important;
        padding: 0.25rem 0.5rem !important;
        border-bottom: 1px solid #eeeeee;
      }
    }
    .rbt-input-main {
      overflow: hidden;
      text-overflow: ellipsis !important;
    }
  }

  form {
    background-color: #fff;

    @media only screen and (max-width: 990px) {
      padding: 0rem 0.5rem 0rem 0.5rem;
      border-radius: 30px;
      border: 1px solid #ccc;
      .h-networks {
        padding: 0px 40px 0px 15px;
        label {
          padding-left: 0px !important;
        }
      }
      .rounded-bg {
        border-radius: 0px 0px 0px 0px !important;
        border-width: 0px !important;
      }
      label {
        padding: 10px 0px 0px 0px !important;
      }
      .form-control {
        padding-left: 0px !important;
      }
      .btn-mb {
        display: block !important;
        padding-right: 0px;
        width: auto !important;
      }
      .current-location {
        margin: 25px 0px 0px 0px;
      }
      .term-error {
        margin-top: 0px;
        margin-left: 0rem;
      }
      .zip-error {
        margin-top: 0.5rem;
        z-index: 1;
      }
    }
    .form-control.is-valid,
    .was-validated .form-control:valid {
      border-width: 0px;
      box-shadow: none;
    }
    .btn-mb {
      display: none;
    }

    .rounded-bg {
      display: flex;
      flex-wrap: wrap;
      padding: 0rem 0rem 0rem 0rem;
      border-radius: 60px 0px 0px 60px;
      border: 1px solid #ccc;
    }
    .form-control-lg {
      border-radius: 0px !important;
      font-size: 1rem !important;
      background-clip: initial !important;
      margin: 0px;
    }
    .form-group {
      margin-bottom: 0rem;
    }
    .form-label,
    label {
      padding: 5px 12px 0px 14px;
      color: gray;
      font-size: 0.8rem;
      margin: 0px;
    }
    .btn-lg {
      padding: 1.08rem 1rem;
      background-color: #656e77;
      border-color: #656e77;
      border-width: 0px;
      border-radius: 0px 60px 60px 0px;
      background-image: none;
      min-width: 70px;
      min-height: 67.53px;
      float: right;
      width: auto;
      max-width: auto;
    }
    button:hover {
      background-color: #005ead;
      border-color: #005ead;
      animation: btn 1s;
      transition: background 1s;
    }
    .zipfield {
      padding-right: 0px;
      padding-left: 0px;
    }
    .term-label {
      padding: 5px 12px 0 10px;
    }
    .zip-label {
      padding: 5px 12px 0 10px;
    }
    //network drop-down
    .h-networks {
      position: relative;
      margin-top: 0rem;
      margin-bottom: 1rem;
      label {
        padding: 1px 0px 0px 4px;
        color: gray;
        font-size: 0.8rem;
        margin: 0px;
      }
      option {
        padding: 5px;
      }
      select.form-control {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        background-color: transparent;
        border: 0;
        padding: 0rem 1rem 0rem 0.2rem;
        border-bottom: 1px solid #ced4da;
        border-radius: 0;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-size: 1rem;
        font-weight: 600;
        height: calc(1.5em + 0.5rem + 2px);
        color: #000;
        margin-bottom: 0px;
      }
      select.form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }
  select::-ms-expand {
    display: none;
  }
  //select column
  .select {
    .form-control {
      font-weight: 700;
      font-size: 1rem !important;
      padding: 0px 15px !important;
      margin: 0px 0px;
      border-width: 0px;
      /*  background-image: none !important;
      -webkit-appearance: auto; */
    }
    .form-control:focus {
      border-color: transparent !important;
      box-shadow: 0 0 0 0.1rem transparent !important;
    }
  }

  //Procedures, Providers, Facilities column
  .hep0 {
    margin: 5px 0px;
    border-left: solid 1px #ccc;
    border-right: solid 1px #ccc;
    @media only screen and (max-with: 990px) {
      width: 100%;
    }
    display: block;

    .css-yk16xz-control {
      border-radius: 0px;
    }
    input[type='text'] {
      margin: 0px 0px;
      border-radius: 0px;
    }
    .close {
      font-weight: 400 !important;
    }
  }

  //zip column
  .hep1 {
    //display: flex;
    label {
      margin-bottom: 0px;
    }
    input[type='text'] {
      margin: 0px 0px;
      border-width: 0px;
      box-shadow: none;
    }
    input[type='radio'] {
      border-width: 0px;
    }
    .clear-zip {
      position: absolute;
      bottom: 8px;
      right: 10px;
      margin-top: 0;
      font-size: 1.1rem;
      font-weight: bolder;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.5;
      cursor: pointer;
      text-align: center;
      padding: 5px 0px;
    }
    .clear-zip:hover {
      background-color: #015497;
    }
  }

  //Search Button
  .btn1 {
    margin: 0px auto;
    background-image: none;
    .btn-lg {
      padding: 1.08rem 1rem;
      background-color: #005ead;
      border-color: #005ead;
      border-width: 0px;
      border-radius: 0px 30px 30px 0px;
      background-image: none;
      width: auto !important;
      float: right;
    }
    button:hover {
      background-color: #005ead;
      border-color: #005ead;
      animation: btn 1s;
      transition: background 1s;
    }
  }
  .btn-success.focus,
  .btn-success:focus,
  .btn-primary:focus,
  .btn-primary.focus {
    color: #fff;
    background-color: none !important;
    border-color: transparent !important;
    border-width: 0px !important;
    box-shadow: 0 0 0 0.1rem transparent !important;
  }
  .result-count {
    font-weight: 400;
    font-size: 1.2rem;
    color: white;
    span {
      color: #13e7e5;
      font-weight: 500;
    }
  }
  .error-text {
    color: #dc3545;
    font-size: 14px;
    position: absolute;
    margin-top: 1rem;
    margin-left: 10px;
  }
  .zip-error {
    color: #dc3545;
    font-size: 14px;
    position: absolute;
    margin-top: 0.8rem;
    min-width: 300px;
  }
  .aync-search {
    margin-bottom: 1rem;
  }

  .current-location {
    color: #000;
    position: absolute;
    font-size: 12px;
    margin: 0px -35px;
    padding: 0px 0px;
    font-family: 'Roboto';
    text-shadow: 0px 0px 0px #131313;
    cursor: pointer;
    .fa-map-pin {
      vertical-align: text-bottom;
      margin: 0px 5px;
    }
    .cl0 {
      border-bottom: 1.2px dotted;
    }
  }
  .rbt {
    margin-bottom: 0rem;
    padding: 0px 2px;
  }
  .rbt-input-hint {
    opacity: 0.1;
  }

  input.rbt-input-main {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    border-width: 0px;
    box-shadow: none;
  }
  input.rbt-input-main::placeholder {
    font-weight: initial;
  }
}

#tooltip-location {
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
  opacity: 0.9;
  .arrow::after {
    border-top-color: #000;
    border-bottom-color: #000;
  }
}
