// vars
$baseFont: 'Roboto Condensed', Arial, sans-serif;
$headerFont: 'Roboto', Arial, sans-serif;
$buttonText: 0.875rem;

// $mainColor: #55ba47;
$mainColor: #009ee1;
$buttonColor: #2199e8;
$buttonColorAlt: #1583cc;
$requiredColor: #ff0000;

// extends
%buttonAlt {
  background-color: $buttonColorAlt !important;
  border-color: $buttonColorAlt !important;
}

%buttonStyle {
  background-color: $buttonColor;
  font-size: $buttonText;
  border-color: $buttonColor;
  &:active {
    @extend %buttonAlt;
    box-shadow: 0 0 0 0 !important;
  }
  &:hover {
    @extend %buttonAlt;
  }
  &:focus {
    box-shadow: 0 0 0 0 !important;
  }
}

%buttonClear {
  background-color: transparent;
  border: none;
  padding: 0px;
  &:active {
    background-color: transparent !important;
    border: none !important;
    box-shadow: 0 0 0 0 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0 !important;
  }
}

// %input-group {
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     width: 100%;
//     margin-bottom: 1rem;
//     -webkit-box-align: stretch;
//     -webkit-align-items: stretch;
//     -ms-flex-align: stretch;
//     align-items: stretch;
// }
