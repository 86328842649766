.cost-estimator-results {
  @media only screen and (max-width: 990px) {
    position: relative;
    display: flex;
    margin: 10px;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    text-align: center !important;
    th {
      width: auto !important;
    }
    .col-form-label {
      text-align: left;
      padding-left: 0px !important;
    }
    .divider0 {
      display: none !important;
    }
    .sort {
      padding-left: 1px !important;
      justify-content: flex-start !important;
    }
    .pc0,
    .pc0 .col {
      padding-right: 0px;
      padding-left: 0px;
    }
    .mdcc-yes,
    .mdcc-no {
      margin-left: 0rem !important;
    }
    .result-count {
      margin-bottom: 0rem !important;
    }
    .legend-icons {
      p {
        padding-left: 0rem !important;
        padding-right: 1rem;
      }
    }
  }
  //legend
  .legend-icons {
    display: flex;

    p {
      font-size: 12px;
      font-weight: 600;
      padding-left: 1rem;
      margin-bottom: 5px !important;
      cursor: pointer;
    }
    .star {
      color: #ff9800;
      font-size: 16px;
      margin-right: 5px;
    }
    .trophy {
      color: #949494;
      margin-right: 5px;
    }
  }
  //Spinner
  .spinner-border {
    width: 4rem;
    height: 4rem;
    border-width: 0.3em;
  }
  .table-header {
    font-weight: 400;
    color: #000;
    font-size: 14px;
    .table-cell {
      display: table-cell;
    }
    strong {
      font-size: 12px;
      color: grey;
      font-weight: 400 !important;
    }
  }
  color: #333;
  background: #f2f2f2;
  padding-top: 20px;
  padding-bottom: 20px;
  a {
    color: #286ce2;
    font-weight: 300;
  }
  .health-navigator-card {
    width: 100%;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0rem;
    margin-bottom: 1rem;
    .star {
      color: #ff9800;
      font-size: 16px;
      margin-right: 5px;
    }
    .trophy {
      color: #949494;
      margin-right: 5px;
    }
  }
  .more-details {
    padding-right: 0px;
    padding-left: 0px;
    .btn-light:focus,
    .btn-light.focus,
    .btn-light:hover {
      color: #005ead;
      background-color: #fff !important;
      border-radius: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
      border: 1px solid #ccc !important;
      box-shadow: 0 0 0 0.1rem transparent !important;
    }
  }
  .filter {
    padding-right: 0px;
    padding-left: 0px;
    display: flex;
    .external-link {
      color: #005ead;
      font-weight: 600;
      font-size: 14px;
      font-family: 'Roboto';
      vertical-align: super;
    }
    .form-control {
      font-weight: 500;
      font-size: 16px !important;
      margin: 0px 0px;
      border-width: 1px;
      padding: 0px 24px 0px 24px;
      /* background-image: none !important;
      -webkit-appearance: auto; */
      border-radius: 0px;
      color: black;
    }
    select::-ms-expand {
      display: none;
    }
    .col-form-label {
      font-size: 14px;
      line-height: 1.5;
      text-align: right;
      padding-right: 0px;
      font-weight: 500;
      padding-left: 90px;
    }
    .sort {
      padding-right: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    p {
      font-size: 14px;
      color: black;
      font-weight: 500;
      margin-bottom: 0px;
    }
    .rs0 {
      text-align: left;
      margin-bottom: 1rem;
      padding-left: 0px;
      padding-top: 10px;
      cursor: pointer;
      p {
        color: #005ead;
        font-weight: bold;
      }
    }
  }

  .container {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  margin-bottom: 0rem;
  text-align: left;

  .cd0 {
    @media only screen and (max-width: 990px) {
      flex-direction: column;
      text-align: left;
      padding-right: 0px;
      padding-left: 0px;
    }
    margin-top: 1.5rem;
    margin-bottom: 0rem;
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    h3 {
      font-size: 1rem;
    }

    .pc0 {
      font-size: 14px;
      padding-right: 0px;
      padding-left: 0px;
      color: black !important;

      p {
        font-weight: 300;
        color: #286ce2;
      }

      border-right: solid 0px #c8c8c8;
      border-left: solid 0px #c8c8c8;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      .pcd0 {
        margin-bottom: 25px;
      }
      .mdcc0 {
        color: #009245;
        vertical-align: middle;
      }
    }
  }
  //provider list table - full-details
  .incentive-list-icon {
    width: 5%;
    padding-right: 3px;
    min-width: 16px;
  }
  .incentive-name {
    padding-left: 0px;
  }
  .incentive-header {
    padding-left: 18px;
  }
  .provider-list {
    //stack table in mobile
    @media only screen and (max-width: 990px) {
      table thead,
      table tbody,
      table tfoot {
        border: 0px solid #f1f1f1;
        background-color: #fefefe;
      }
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
      tr {
        border: 1px solid #ccc;
      }
      td {
        border: none;
        padding: 5px;
        border-bottom: 1px solid #eee;
        position: relative;
        white-space: normal;
        text-align: left;
      }
      .td-value {
        float: right;
      }
      .th-value {
        font-weight: 700;
      }
    }
    font-size: 14px;
    padding: 0px 42px;
    table {
      margin-top: 1rem;
      .mdcc-yes {
        color: #7cbe00;
        font-size: 14px;
        margin-left: 3rem;
      }
      .mdcc-no {
        color: #be0000;
        font-size: 14px;
        margin-left: 3rem;
      }
    }
  }
}

//Modal Content Fix
.modal-body {
  @media only screen and (max-width: 990px) {
    .card-text {
      max-width: 275px;
    }
  }
  color: #000000de;

  h3 {
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 0.85rem;
    text-align: left;
    font-weight: 600;
  }
  ul {
    list-style: none;
    padding: 0px;
    text-align: left;
    font-size: 0.85rem;
    color: #000000de;
    margin-left: 0px;
  }
  button {
    border-radius: 0px;
  }
}
.modal-header .close {
  padding: 4px 10px !important;
  margin: 0px 0rem 0rem auto !important;
  background: #005ead;
  border-radius: 50% !important;
  color: #fff;
}
.modal-header span {
  vertical-align: text-top;
}
.modal-footer {
  border-top: 0px solid #dee2e6 !important;
  display: inline-block !important;
  button {
    border-radius: 0px;
  }
  a {
    color: #ffffff !important;
  }
  a:hover,
  button:hover {
    text-decoration: none;
  }
}
.modal-body {
  text-align: center;
  padding: 1.1rem;
  color: #000;
  .member-card {
    margin-bottom: 2rem;
  }
  .patient-code {
    margin-top: 1rem;
    margin-bottom: 1rem;
    strong {
      font-size: 1rem;
      padding: 0px 3px;
      line-height: 1.5;
    }
  }
  .inputGroup {
    background-color: #fff;
    display: block;
    margin: 10px 0;
    position: relative;
    .digits {
      font-size: 14px;
      font-weight: 600;
      color: #000;
    }
    .network-text {
      font-weight: 700;
      color: #005ead;
    }
    label {
      padding: 12px 12px;
      width: 100%;
      display: block;
      text-align: left;
      color: #3c454c;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
      border: solid 1px #ccc;
      margin: 0px;

      &:after {
        width: 20px;
        height: 20px;
        content: '';
        border: 2px solid #d1d7dc;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }

      &:hover {
        background-color: aliceblue;
        color: #005ead !important;
      }
      &:focus {
        font-weight: 700;
        color: #005ead !important;
      }
    }

    input:checked ~ label {
      color: #005ead;
      font-weight: 700;
      background: aliceblue;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }

      &:after {
        border-color: #d1d1d1;
        content: '';
        width: 20px;
        height: 20px;
        border: 2px solid #d1d7dc;
        background-color: #005ead;
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
  }
}
.modal-header {
  border-bottom: 1px solid #005ead;
  background: #005ead;
  color: #fff;
  font-weight: 400;
  align-items: center;
  padding: 0.5rem 1rem !important;
  .close {
    background: #ffffff00 !important;
    font-weight: 300;
    opacity: 1;
  }
  .close:hover {
    color: #fff !important;
  }
}
.modal-footer {
  text-align: right;
  button {
    padding: 5px 20px;
  }
  .back-to-home {
    color: #005ead !important;
    font-weight: 700;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .btn-outline-info {
    color: #005ead !important;
    border-color: #005ead !important;
    border-width: 0px !important;
    font-weight: 500;
  }
  .back-icon {
    font-size: 10px;
  }
  .btn-outline-info:hover {
    background: #005fad00 !important;
    color: #005ead !important;
  }
  .btn-info {
    color: #fff;
    background-color: #005ead !important;
    border-color: #005ead !important;
  }
  .btn-info:hover {
    background: #286ce2 !important;
  }
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: 400 !important;
}
//tooltip fix
#incentive-tooltip {
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #005ead;
  border-radius: 0.25rem;
  //opacity: 1 !important;
  .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #005ead;
  }
}
