// TODO: replace w/ _variables import eventually when moved to common location
$errorText: #cc0000;

%section-spacing {
  padding: 1rem;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.toDoList {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  margin-bottom: 1rem;
  .button.viewToDos {
    margin-bottom: 0;
    float: right;
  }
  > .card-section {
    padding: 1rem;
    overflow: hidden;
  }
}
.loadingFiller {
  @extend %section-spacing;
  svg {
    width: 90%;
    height: 350px;
    margin: 20px;
    display: inline-block;
  }
}
.errorToDoLoad {
  @extend %section-spacing;
  text-align: center;
  .errorMSG {
    color: $errorText;
  }
}
.completedToDoLoad {
  @extend %section-spacing;
}
