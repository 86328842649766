.timetrade-iframe {
  margin: 0;
  padding: 0;
  height: 70vh !important;
  width: 100%;
  border: none;
}
.mypoints-modal-dialog {
  max-width: 770px !important;
  margin: 1.75rem auto !important;
}
.mypoints-modal-body {
  border: 2px solid black;
}
.mypoints-modal-header {
  border: 1px solid #ffffff;
  background: #055194;
  color: #fff;
  font-weight: 400;
  padding: 0rem 0rem !important;
  position: absolute;
  z-index: 1;
  right: 0px;
  margin: -12px;
  box-shadow: black 0px 0px 4px;
}
.logo-link {
  display: none !important;
}
