.points-completed {
  padding-top: 1rem;
  .list-group {
    padding: 0rem;
    .list-group-item {
      margin-bottom: 2rem;
      display: flex;
      padding: 0px;
      border-width: 0px;
      a {
        color: #009ee2;
        border-bottom: 1px solid;
      }
      a:hover {
        text-decoration: none !important;
      }
      p {
        margin-bottom: 0rem;
      }
      h4 {
        font-size: 1rem;
        color: #009ee2;
      }
      img {
        border-radius: 50%;
        border: solid 2px #009ee294;
        box-shadow: 0px 0px 8px 8px #9cd7f1;
        padding: 0.5rem;
      }
      .list-image {
        padding-top: 1rem;
        padding-right: 1rem;
        max-width: 90px;
        width: 100%;
      }
    }
  }
  .image-right {
    width: 100%;
    margin: 0px auto;
    display: flex;
    justify-content: left;
    .points-completed-bg {
      border: solid 100px #f0f0f2;
      border-radius: 50%;
    }
    img {
      border-radius: 50%;
      border: solid 4px #009ee2;
      box-shadow: 0px 0px 0px 40px #e1e2e4;
      padding: 0rem;
      max-height: 200px;
      max-width: 200px;
    }
  }
}
