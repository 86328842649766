@import '../variables';

.chat-button-wellness {
  button {
    display: flex;
    flex-direction: row;
    background-color: $mainColor;
    font-family: $headerFont;
    border: 0px;
    padding: 0px;
    height: 52px;
    width: 145px;
    transition: background-color 0.25s ease-out;
    &:hover {
      background-color: #1583cc;
    }
    img {
      margin-left: 8px;
      height: 35px;
      align-self: center;
    }
    .title {
      margin-left: 8px;
      color: white;
      align-self: center;
      font-size: 1.1rem;
    }
  }
}
