.self-checker-history {
  @media only screen and (max-width: 768px) {
    /* overflow-y: scroll;
    max-height: 40rem; */
    .columns:last-child:not(:first-child) {
      float: right;
    }
    .colRight {
      float: right;
    }
    .cdcCard {
      align-items: center !important;
    }
    .no-pad-left {
      padding-top: 0px !important;
    }
    .center-col-10 {
      display: block !important;
    }
  }
  .no-pad-left {
    padding-left: 0px;
  }
  .timeRight {
    float: right !important;
    font-size: 14px;
  }
  .cdcCard {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #948e94;
    border-radius: 4px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 0.8rem;
  }
  .cdcCard1 {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #948e94;
    border-radius: 4px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 2rem;
    flex-direction: column;
  }
  .colRight {
    font-size: 14px;
  }
  .backBtn {
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.28px;
    color: #1961c5;
    font-weight: bold;
    padding-right: 15px;
    padding-left: 15px;
    cursor: pointer;
  }
  .backIcon {
    font-size: 16px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
  .historyRow {
    padding-right: 10px;
    padding-left: 10px;
  }
  .tablePrint {
    text-align: right;
    font-size: 1.2rem;
    color: #1961c5;
    cursor: pointer;
    svg {
      vertical-align: middle;
    }
  }
  .h-home {
    color: #f5a01a;
    font-size: 24px;
  }
  .h-work {
    color: #55ba47;
  }
  .h-er {
    color: #ed1c24;
  }

  span {
    vertical-align: middle;
    margin-right: 5px;
    font-weight: bolder;
    font-size: 20px;

    .button {
      float: right;
      height: auto;
    }
  }
  .m-icon {
    padding-top: 9px;
  }
  @media only screen and (max-width: 768px) {
    .tablePrint {
      vertical-align: top !important;
      //padding: 30px 0px;
    }
    .m-icon {
      padding-top: 7px;
    }
  }
}
