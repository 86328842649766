.add-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  .add-doctor-modal {
    width: auto;
    margin: 80px auto;
    max-width: 450px;
    @media only screen and (max-width: 600px) {
      margin: 20% 2%;
    }
  }
  .cardtitle {
    padding-left: 0.2rem !important;
  }
  .error-text {
    width: 100%;
    font-size: 0.75rem;
    color: #dc3545;
    margin: -0.8rem 0.925rem 0rem 0.925rem;
    font-weight: bold;
  }
  .button.hollow {
    bottom: 0px !important;
    right: 0px !important;
  }
  .card-section {
    padding: 1rem;
  }
  .new-doctor-inputs {
    margin: 15px;
  }
  .card.linked {
    padding-bottom: 0rem;
    margin-bottom: 0rem;
  }
  .savecancel-btn {
    display: block;
    position: relative;
    .cancel-btn {
      float: left !important;
      margin: 0.94rem !important;
      clear: none !important;
      position: relative !important;
      text-transform: none !important;
    }
    .save-btn {
      float: right !important;
      clear: none !important;
      margin: 0.94rem !important;
      position: relative !important;
      bottom: 0px !important;
      right: 0px !important;
      color: white;
    }
  }
  .save-spinner {
    padding-top: 20px;
  }
}
