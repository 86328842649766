// TODO: replace w/ _variables import eventually when moved to common location
$color_black_squeeze_approx: #f2fafd;
$color_cerulean_approx: #009ee1;
$color_seashell_approx: #f0f1f2;
$color_pacific_blue_approx: #00b2b0;
$color_apple_approx: #55ba47;
$color_tree_poppy_approx: #f5a01a;

.homepageSubsections {
  .background-generic {
    background-color: $color_black_squeeze_approx;
  }
  .background-health {
    background-color: $color_cerulean_approx;
  }
  .background-health {
    background-image: linear-gradient(
      to right,
      $color_cerulean_approx 40%,
      $color_seashell_approx 40%
    );
    border-bottom: 6px solid $color_cerulean_approx;
  }
  .background-finance {
    background-image: linear-gradient(
      to right,
      $color_pacific_blue_approx 40%,
      $color_seashell_approx 40%
    );
    border-bottom: 6px solid $color_pacific_blue_approx;
  }
  .background-wellbeing {
    background-image: linear-gradient(
      to right,
      $color_apple_approx 40%,
      $color_seashell_approx 40%
    );
    border-bottom: 6px solid $color_apple_approx;
  }
  .background-worklife {
    background-image: linear-gradient(
      to right,
      $color_tree_poppy_approx 40%,
      $color_seashell_approx 40%
    );
    border-bottom: 6px solid $color_tree_poppy_approx;
  }
  .background-finance {
    background-color: $color_pacific_blue_approx;
  }
  .background-wellbeing {
    background-color: $color_apple_approx;
  }
  .background-worklife {
    background-color: $color_tree_poppy_approx;
  }
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
