.self-checker-panel {
  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 1.3rem !important;
    }
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  ul {
    li {
      font-size: 1rem !important;
    }
    .list-unstyled {
      margin-left: -1.25rem;
    }
  }
  .btns {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    background-color: #005ead;
  }
  .additional-text {
    text-align: left !important;
    font-size: 1rem;
  }
  .back-button {
    color: #005ead;
    font-weight: 600;
  }

  .btn:hover {
    text-decoration: none;
  }
  .btn.focus,
  .btn:focus {
    outline: 0;
    box-shadow: none !important;
  }
}
