.points-earned {
  .date-range-link {
    color: white !important;
  }
  .pagination {
    margin-top: 1rem;
  }
  .rollup-rules-col {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .rollup-pagination {
    background-color: #fff !important;
  }
  .accordion-detial-toggle {
    border-width: 0px !important;
    box-shadow: 0px 0px 0px 0px #fff !important;
    outline: 0px !important;
    background: #eff2f400 !important;
  }
}

.outcomeColPE.IT_Biometr,
.outcomeColPE.IT_BioMYoY {
  color: #00a161;
  font-weight: bold;
}

.points-earned-alert {
  margin-top: 2rem;
}
.error-message {
  margin-top: 2rem;
}
.alert-secondary {
  color: #383d41 !important;
  background-color: #e2e3e5 !important;
  border-color: #d6d8db !important;
}
.alert-danger {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}
