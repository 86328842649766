@import "../../mixins";

.confirmation-modal-overlay {
  @include modal-overlay-deafult;
}

.confirmation-modal {
  @include modal-default;
  .acknowledgement {
    text-align: center;
  }
  margin: 15% auto;
  width: auto;
  max-width: 28.13rem;
  transform: none !important;
  -webkit-transform: none !important;
  @media only screen and (max-width: 600px) {
    margin: 20% 2%;
    /* width: auto !important;
    max-width: auto !important; */
  }
  .card-buttons {
    max-width: 420px !important;
    margin: 0px auto !important;
    position: relative !important;
    padding: 2rem 2rem 2rem 2rem !important;
  }
  .card.linked {
    padding-bottom: 0rem !important;
  }
  .cancel-btn {
    left: 15px;
  }
}
