@import '../variables';

.error-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  text-align: center;
  p:first-child {
    margin-top: 20px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #d40000;
  }
  img {
    margin: 10px 0px 20px;
  }
  button {
    @extend %buttonStyle;
    margin: 10px 0px;
  }
}
