$brightBlue: #009ee1;

.biometrics-fax-view {
  width: 100%;
  .tabs-content {
    padding-bottom: 4rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      .card-section {
        padding: 1%;
      }
      .tabs-panel {
        padding: 0.4rem;
      }
    }
    .select-bio-overflow {
      overflow: visible;
    }
    [data-tooltipz] {
      position: relative;
      cursor: help;
      span {
        border-bottom: dotted 1px #8a8a8a;
        padding: 2px 0px;
        font-weight: 600;
        color: #555;
      }
    }

    [data-tooltipz]:hover::before {
      content: attr(data-tooltipz);
      width: 240px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 3px;
      padding: 3px;
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -120px;
      z-index: 198;
    }
    [data-tooltipz]:hover::after {
      content: ' ';
      position: absolute;
      top: -1%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
    .recipient-table {
      img {
        max-width: 80%;
      }
    }
  }
  table {
    tr,
    td,
    th {
      width: auto !important;
      text-align: left !important;
    }
  }
}
