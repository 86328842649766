.send-fax-view {
  h4 {
    padding: 1.5rem 0rem;
    font-size: 1.5rem;
  }
  .tabs-panel {
    padding: 0.4rem;
  }
  .submit-row {
    margin-right: 0;
    display: block;
  }
  .submit-section {
    width: 100%;
    min-height: 40px;
    .submit-wrapper {
      .submit-wait {
        float: left;
        padding: 15px;
      }
      .button {
        margin-right: 15px;
      }
      float: right;
    }

    [data-tooltipz] {
      cursor: not-allowed !important;
      font-size: 85% !important;
    }

    [data-tooltipz]:hover::before {
      content: attr(data-tooltipz);
      left: 60% !important;
      margin-left: -200px !important;
    }
    [data-tooltipz]:hover::after {
      top: -2% !important;
      left: 70% !important;
    }
  }
}
