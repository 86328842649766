@import '../../mixins';

.authorization-modal-overlay {
  @include modal-overlay-deafult;
}

.authorization-modal {
  @include modal-default;
  .acknowledgement {
    text-align: center;
  }
  margin: 15% auto;
  width: auto;
  max-width: 28.13rem;
  transform: none !important;
  -webkit-transform: none !important;
  @media only screen and (max-width: 600px) {
    margin: 20% 2%;
    /* width: auto !important;
    max-width: auto !important; */
  }
  .accept-btn {
    position: relative !important;
    clear: none !important;
    float: right !important;
    text-transform: uppercase !important;
    bottom: 0px !important;
    right: 0px !important;
  }
  .cancel-btn {
    bottom: 0px !important;
    position: relative !important;
    clear: none !important;
    float: left !important;
    bottom: 0px !important;
    right: 0px !important;
    margin-right: 20px;
  }
  .card-buttons {
    max-width: 420px !important;
    margin: 0px auto !important;
    position: relative !important;
    padding: 0rem 2rem 0rem 2rem !important;
  }
  .card.linked {
    padding-bottom: 1rem !important;
  }
}
