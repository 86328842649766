#chatMountComponent .mount-container {
  position: fixed;
  z-index: 1001;
  bottom: 0;
  right: 20px;
  width: 400px;
  height: 475px;
  opacity: 1;

  @media screen and (max-width: 450px) {
    right: 0px;
    width: 100vw;
    height: 100%; // percent instead of vh for mobile browsers
    bottom: auto;
    top: 0;
    left: 0;
  }
}

#chatMountComponent .show {
  -webkit-animation: moveIn 1s ease;
  animation: moveIn 1s ease;
}

#chatMountComponent .hide {
  // -webkit-animation: fadeOut 1s;
  // animation: fadeOut 1s;
  border: 10px solid yellow;
}

// animations
@keyframes moveIn {
  0% {
    // opacity: 0;
    transform: translateY(475px);
  }

  100% {
    // opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
