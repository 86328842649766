.facility-results {
  @media only screen and (max-width: 990px) {
  }
  .incentive-rating {
    width: 45%;
    overflow: visible;
  }

  .result-count {
    color: #000;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    margin-bottom: 1rem;
    padding-left: 0px;
    padding-top: 10px;
    strong {
      color: #000 !important;
      font-weight: 400 !important;
    }
  }
}
