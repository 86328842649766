.spine-providers {
  width: 100%;
  .spine-cards {
    display: flex;
    padding: 1rem 2rem;
    flex-flow: row;
    flex-wrap: wrap !important;
    justify-content: space-between;
  }

  .spine-cards-mobile {
    padding: 1rem 0.5rem;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap !important;
    justify-content: space-between;
  }

  .spine-button {
    font-size: 14px !important;
    border-radius: 0px !important;
    color: rgb(65, 207, 96) !important;
    font-weight: 500;
    border-top: 1px solid rgb(65, 207, 96) !important;
    background-color: #edf3f0 !important;
    width: 100%;
    margin: 0px !important;
  }

  .spine-button:hover {
    color: black !important;
    border-top: 1px solid rgb(65, 207, 96) !important;
    background-color: #edf3f0 !important;
    outline-color: #edf3f0 !important;
  }

  .spine-button:active {
    border-top: 1px solid rgb(65, 207, 96) !important;
    background-color: #edf3f0 !important;
    outline-color: #edf3f0 !important;
  }
  .spine-button:focus {
    border-top: 1px solid rgb(65, 207, 96) !important;
    background-color: #edf3f0 !important;
    outline-color: #edf3f0 !important;
  }
  .spine-button:visited {
    border-top: 1px solid rgb(65, 207, 96) !important;
    background-color: #edf3f0 !important;
    outline-color: #edf3f0 !important;
  }

  .spine-providers-error {
    color: red !important;
  }
}
