.spine-program-card {
  padding-top: 1rem;
  .incentive-error {
    font-size: 14px;
    font-weight: 500;
    color: red;
  }
  @media only screen and (max-width: 990px) {
    //padding: 1rem 0.5rem !important;
    p {
      padding-right: 0rem !important;
      text-align: left;
    }
    .spine-card-text {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
    .spine-incentive-text {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  }
  word-wrap: break-word;
  background-color: #edf3f0;
  background-clip: border-box;
  border: 1px solid rgb(65, 207, 96);
  border-radius: 0rem;
  margin-bottom: 0.5rem;
  .spine-card-text {
    padding-right: 0rem;
    padding-left: 3rem;
  }

  .spine-incentive-text {
    padding-left: 0;
    padding-right: 3rem;
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    color: rgb(65, 207, 96);
    text-align: left;
  }
  h4 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
    text-align: left;
  }

  .card-paragraph {
    font-size: 14px;
    padding-right: 9rem;
  }

  .program-amount {
    float: right;
  }
  .cta {
    margin-bottom: 0.5rem;
  }
}
