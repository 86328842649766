@import '../../variables';
@import '../../mixins';

// extends //
%ring-rumber-alt {
  font-size: 0.6em;
  line-height: 1;
  text-anchor: middle;
}

//  styles  //
.points-meter {
  padding-top: 1rem;

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  figure {
    margin: 0 auto 20px;
    width: 180px;
    height: 180px;
    text-align: center;

    .figure-content {
      text-align: center;
      svg {
        height: 180px;
      }
    }
  }

  .progress {
    background: none;
    box-shadow: none;
    @include transition(1s ease-in-out);
    transform: rotate(-90deg);
  }

  .button {
    &.hollow {
      margin: 0;
    }
  }

  .ring-text {
    font-family: $baseFont;
    font-size: 40px;
    font-weight: bold;
    margin-top: -191px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .ring-number {
      font-size: 54px;
      line-height: 1;
      fill: $ringNumber;
      font-weight: bold;
      text-align: center;

      &.incomplete {
        @extend %ring-rumber-alt;
        color: $errorText;
      }

      &.none {
        @extend %ring-rumber-alt;
        color: $ringNone;
      }
    }

    .ring-label {
      font-size: 22px;
      font-weight: bold;
      line-height: 1;
      color: $ringLabel;
      text-align: center;

      &.incomplete {
        font-size: 20px;
        margin: 0;
        padding: 0 10px;
      }
    }
  }

  .link-list {
    margin-top: 0;

    p,
    a {
      font-size: 1rem;
    }

    a {
      div {
        margin: 4px 0;
      }
      .label {
        font-size: 14px;
      }
    }
  }
}

.points-meter.allComplete .allLevelsComplete,
.points-meter.timeLag .timeLagText,
.points-meter.allComplete .ring-label.complete,
.points-meter.zeroPoints .justStarting {
  display: block;
}

.points-meter.outstandingRequirements .incompleteReq,
.points-meter.outstandingRequirements .incomplete {
  display: block;
}

.points-meter.requiredItemsExist div.requiredItems {
  display: block;
}

.points-meter.requiredItemsExist span.requiredItems {
  display: inline;
}
