#quickLinksComponent {
  background: #005dac;
}

@media only screen and (min-width: 992px) {
  .quick-links-item.col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 12.5%;
  }
}
.quick-links-view {
  @media (min-width: 992px) {
    .col-lg-13 {
      flex: 0 0 16.666667%;
      max-width: 14.28%;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .row {
    @media only screen and (max-width: 768px) {
      //align-items: flex-start;
    }
  }
  .col-6 {
    @media only screen and (max-width: 768px) {
      flex: 0 0 49.5%;
      max-width: 49.5%;
    }
  }
  .spinner-border {
    font-size: 0.3rem;
    opacity: 0.2;
  }
  background: #005dac;
  width: 100%;
  h3 {
    color: #ffffff;
    margin-bottom: 1rem;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
      text-align: center;
    }
  }
  a:hover {
    text-decoration: none;
  }
  .spinner-border {
    width: 2rem !important;
    height: 2rem !important;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
}
