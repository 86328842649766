@import '../variables';

.topic-menu {
  flex-direction: column;
  display: flex;
  min-height: 0;
  .row {
    flex-direction: column;
    display: flex;
    min-height: 0;
    .columns {
      padding-left: 0px !important;
      padding-right: 0px !important;
      flex-direction: column;
      display: flex;
      min-height: 0;

      div.list-container {
        // height: 410px;
        overflow: auto;
        min-height: 0;
        flex-direction: column;
        display: flex;
        flex-grow: 1;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS styles go here */
          height: 410px;
        }
        .list-group {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
          flex-direction: column;
          padding-left: 0;
          margin-bottom: 0;
          padding: 20px;

          .list-group-item {
            position: relative;
            padding: 0.75rem 1.25rem;
            margin-bottom: -1px;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.125);
            &:first-child {
              border-top-left-radius: 0.25rem;
              border-top-right-radius: 0.25rem;
            }
            &:last-child {
              margin-bottom: 0;
              border-bottom-right-radius: 0.25rem;
              border-bottom-left-radius: 0.25rem;
            }
          }

          button {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            i {
              margin-left: auto !important;
              // padding: 5px;
            }
          }
        }
      }
    }
  }
}

.no-topics-error {
  padding: 20px;
}
