@import '../variables';

body {
  font-family: $baseFont;
}

.chat-pop-up {
  display: flex;
  flex-direction: column;
  background: #fff;
  // border: 1px solid black;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  height: inherit;
}
