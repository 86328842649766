.spine-contact {
  text-align: center !important;
  position: absolute;
  bottom: 10px;
  left: 5.5rem;
  @media only screen and (max-width: 990px) {
    left: 4rem;
    margin-top: 10px;
    button {
      width: 100%;
    }
  }
  line-height: 1;
  p {
    margin-bottom: 1px;
  }

  h3 {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 1px;
    margin-top: 0px;
    color: #333;
  }
  button {
    margin-bottom: 6px;
    margin-top: 6px;
    background: #005ead;
    border-color: #005ead;
    transition: background 2s;
    border-radius: 0px;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    max-width: 180px;
    z-index: 2;
    position: relative;
    color: #fff !important;
  }
  button:hover {
    background: #286ce2;
    border-color: #286ce2;
    transition: background 1s;
  }
  .pi0 {
    @media only screen and (max-width: 990px) {
      font-size: 0.85rem !important;
      padding: 0rem 1.25rem;
      margin-bottom: 10px;
      text-align: center;
    }
    font-size: 12px !important;
    small {
      font-size: 12px;
    }
  }
  .btn-success.focus,
  .btn-success:focus,
  .btn-primary:focus,
  .btn-primary.focus {
    color: #fff;
    background-color: #005ead !important;
    border-color: #005ead !important;
    box-shadow: 0 0 0 0.1rem #005ead !important;
  }
}
.spine-contact-mobile {
  text-align: center !important;
  @media only screen and (max-width: 990px) {
    left: 4rem;
    margin-top: 10px;
    button {
      width: 100%;
    }
  }
  line-height: 1;
  p {
    margin-bottom: 1px;
  }

  h3 {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 1px;
    margin-top: 0px;
    color: #333;
  }
  button {
    margin-bottom: 6px;
    margin-top: 6px;
    background: #005ead;
    border-color: #005ead;
    transition: background 2s;
    border-radius: 0px;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    max-width: 180px;
    z-index: 2;
    position: relative;
    color: #fff !important;
  }
  button:hover {
    background: #286ce2;
    border-color: #286ce2;
    transition: background 1s;
  }
  .pi0 {
    @media only screen and (max-width: 990px) {
      font-size: 0.85rem !important;
      padding: 0rem 1.25rem;
      margin-bottom: 10px;
      text-align: center;
    }
    font-size: 12px !important;
    small {
      font-size: 12px;
    }
  }
  .btn-success.focus,
  .btn-success:focus,
  .btn-primary:focus,
  .btn-primary.focus {
    color: #fff;
    background-color: #005ead !important;
    border-color: #005ead !important;
    box-shadow: 0 0 0 0.1rem #005ead !important;
  }
}
