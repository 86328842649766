.quick-links-item {
  color: #005dac;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 0px;
  margin: 0px 0;
  padding-right: 5px !important;
  padding-left: 5px !important;
  @media only screen and (max-width: 768px) {
    //
  }
  .quick-link-card {
    background-color: #ffffff !important;
    background: #ffffff !important;
    color: #005dac !important;
    vertical-align: inherit;
    height: 135px;
    border-radius: 3px;
    margin-bottom: 0rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    @media only screen and (max-width: 768px) {
      margin-bottom: 0rem;
      height: 62px;
      display: flex;
      padding: 15px 1px;
    }
    img {
      display: block;
      margin: 16px auto;
      width: 40px;
      height: 45px;
      @media only screen and (max-width: 768px) {
        margin: 5px 5px;
        width: 25px;
        height: 100%;
        max-width: 25px;
        min-width: 25px;
      }
    }
    a {
      color: #005dac !important;
    }
    .quick-links-title {
      font-weight: 500 !important;
      text-shadow: 0px 0px 0px #000000;
      color: #005dac;
      font-size: 14px;
      padding: 0px 2px;
      text-align: center;
      margin-bottom: 5px;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
        text-align: left;
        margin-left: 0px;
        margin-bottom: 0px;
      }
    }
    .card-body {
      padding: 0px;
    }
    .quick-links-group {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      @media only screen and (max-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
    .quick-links-group-item {
      position: relative;
      display: block;
    }
  }
  a > div.card:hover {
    background-color: #adcbe7 !important;
    color: #000000;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    border: 1px solid #085dac;
    text-decoration: none !important;
  }
}
