@import '../variables';

.header {
  font-family: $headerFont;
  background-color: $mainColor;
  color: #fff;
  height: 62px;
  //flex: 0 0 62px;
  //display: flex;

  .row {
    //display: flex;
    height: inherit;

    .columns {
      display: flex;
      height: inherit;
      padding-left: 0px;
      padding-right: 0px;

      div {
        &:last-child {
          margin-left: auto !important;
          padding: 5px;
        }
        .button {
          background-color: transparent;
          border: none;
          padding: 0px;
          min-width: 24px;
          height: 24px;
          margin-top: 0px;
          cursor: pointer;
          i {
            color: #fff;
          }
        }
      }

      .icon {
        width: 40px;
        margin-left: 14px;
        align-self: center;
        img {
          width: 40px;
        }
      }

      .title {
        margin-left: 14px;
        align-self: center;
        font-size: 1.3rem;
      }

      .error {
        width: 25px;
        margin-left: 24px;
        //margin-left: auto !important;
        align-self: center;
        &:hover {
          .tool-tip {
            visibility: visible;
            opacity: 1;
          }
        }
        img {
          width: 25px;
        }
        .tool-tip {
          visibility: hidden;
          width: 130px;
          background-color: #ee3d3d;
          color: #fff;
          text-align: center;
          border-radius: 3px;
          padding: 2px 0;
          position: absolute;
          z-index: 1001;
          // bottom: 125%;
          left: 52%;
          //margin-left: -60px;
          opacity: 0;
          transition: opacity 0.3s;
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent #ee3d3d transparent transparent;
          }
        }
      }
    }
  }
}
