$hn-blue: rgb(0, 94, 173);
.networks-popup {
  @media only screen and (max-width: 990px) {
    margin: 0px 0px;
  }
  color: #000;
  position: absolute;
  font-size: 12px;
  width: 300px;
  margin: 20px 0px;
  padding: 0px 0px;
  text-shadow: 0px 0px 0px #131313;
  .network-label {
    font-size: 12px;
    cursor: pointer;
    color: #000000;
    .fa-reg-card {
      vertical-align: text-bottom;
      margin: 0px 5px;
    }
    span {
      color: $hn-blue;
      text-decoration: underline;
      .fa-right {
        vertical-align: bottom;
        color: $hn-blue;
      }
    }
  }
}

.network-loading {
  margin-bottom: 0.8rem;
  font-size: 0.9rem;
  text-align: center;
  .network-spinner {
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 3px 10px;
  }
}
.network-error {
  @media only screen and (max-width: 990px) {
    margin: -6px 0px;
  }
  color: #ff0018;
  border-color: #f1f1f1;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  padding: 0px 6px;
  position: absolute;
  font-size: 12px;
  width: 100%;
  margin: 10px 0px;
  line-height: 1.2;
}
//Modal
.modal {
  .modal-body {
    .heading-card {
      border: 0px solid #ccc;
      padding: 0px;
      margin-bottom: 0px !important;
      vertical-align: sub;
      .heading-body {
        align-items: flex-end;
      }
    }
    .modal-link {
      color: #005ead;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
    }
    p {
      text-align: left;
      font-size: 14px;
      margin-bottom: 4px;
      color: #000;
    }
    img {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .member-card {
      background-image: url(../images/member_identification_card.png);
      height: 241.63px;
      width: 464px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .back-icon {
      font-size: 10px;
    }
    .card {
      margin-bottom: 0.5rem;
      .card-body {
        display: flex;
        padding: 10px 15px;
        font-size: 14px;
        .heading {
          font-size: 12px !important;
          font-weight: 400 !important;
          color: gray !important;
        }
        .digits {
          font-size: 14px;
          font-weight: 500;
          color: #000;
          margin-right: 1rem;
          max-width: 40px;
          text-align: center;
        }
        a {
          font-weight: bold;
          color: #005ead;
          text-align: left;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}
