.covid-self-checker-view {
  @media only screen and (max-width: 768px) {
    .rw0 {
      padding: 0rem !important;
    }
  }
  margin: 0px auto;

  label {
    display: flex;
    margin: 0px;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: initial;
    color: #000000;
    align-items: baseline;
  }

  .cdc-label {
    margin-bottom: 0.5rem;
  }
  .certificateHeader {
    font-weight: bold;
    margin-top: 1rem;
    font-size: 1.1rem;
  }
  .center-col-10 {
    display: flex;
    align-items: center;
  }
  .cdcCard0 {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #948e94;
    border-radius: 4px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 2rem;
    flex-direction: column;
    h3 {
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
      color: #000000;
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }
    p {
      text-align: center;
      font-size: 1rem;
      color: #000000;
    }
  }
  @media print {
    .noprint {
      display: none !important;
    }
    nav {
      display: none;
    }
    .column.row.subpageHeader {
      display: none;
    }
  }
  svg.spinner {
    margin: auto !important;
    width: 50% !important;
    padding: 1px;
  }
  .fsborder {
    border-right: 1px solid #c8c8c8;
  }
  .material-spinner-panel {
    height: 36rem;
  }

  .button {
    border-radius: 0px;
  }
  .intro {
    padding: 0.8rem 0rem;
    justify-content: center;
    p {
      text-align: left;
      padding: 0px;
      margin: 0px;
    }
  }
  .rd0 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .fs0 {
    margin: 16px auto 14px auto;
    padding-right: 0px;
    padding-left: 0px;
    input.button:hover,
    input.button:focus {
      background: #00008b;
    }
    .button {
      margin-right: 1rem;
      padding: 0.85px 1rem;
    }
  }
  p {
    text-align: center;
  }
  .rw0 {
    border: #005ead solid 0px;
    margin-bottom: 1rem;
    padding: 0rem 1rem;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch !important;
  }
  .fs1 {
    text-align: left;
    .button.disabled,
    .button[disabled] {
      opacity: 0.25;
      cursor: not-allowed;
      color: #fff !important;
      background-color: #007bff;
      border-color: #007bff;
    }
    .note {
      font-style: italic;
      font-size: 1rem;
      text-align: left;
      margin-top: 0.5rem;
    }
  }

  .text-light {
    color: #ffffff !important;
  }

  // alert
  .alert-danger {
    padding: 5px 20px;
    background-color: #f44336;
    color: white;
    margin-bottom: 5px;
  }

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: black;
  }
  nav {
    padding-right: 0px;
    padding-left: 0px;
    font-weight: bold;
    align-items: center;
    display: flex;
    justify-content: center;
    a {
      display: inline-block;
      padding: 10px 20px 0px 20px;
      text-decoration: none;
      color: #005ead;
      font-weight: 500;
      line-height: 1.8;
    }

    .active {
      color: #005ead;
      font-weight: 500;
      text-transform: uppercase;
      border-bottom: 3px solid;
    }
  }

  section {
    padding: 0px;
    padding: 0px;
  }
  @media only screen and (max-width: 768px) {
    .panel-container {
      margin: 0px 20px;
    }
    .temp-01 {
      margin-top: 1rem;
    }
    .cdc-nav {
      margin: 0px 20px;

      a {
        padding: 10px 10px 0px 10px !important;
      }
    }
    .cdc-no-pad {
      padding-right: 0px;
      padding-left: 0px;
    }
    .no-pad-left {
      padding-left: 0px;
      padding-top: 9px;
    }
    .rw0 {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .backBtn {
      padding-left: 0px !important;
    }
    .fs1 {
      display: table;
      float: right !important;
      text-align: center;
      width: 100%;
      .button[disabled],
      .button {
        width: 100%;
      }
    }
    .fs2 {
      width: 100%;
    }
    .fs0 {
      .button {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    .btntooltip:hover:before {
      bottom: 65px !important;
      display: none !important;
    }
    .btntooltip:hover:after {
      left: 0px !important;
      bottom: 53px !important;
      margin-left: 145px !important;
      display: none !important;
    }
  }
  .cdc-result-selection {
    //background-color: darkblue;
    color: #000000;
  }
  section::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  section::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  section::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #bdbdbd;
  }
  .p0 {
    text-align: left;
    //margin-top: 1rem;
    font-size: 1rem;
    color: #2d383f;
    font-weight: 500;
  }
  .h-home {
    color: #ffffff !important;
    background: #f5a01a;
    border-radius: 50%;
    padding: 6px 8px;
    margin: 0px 10px 0px 0px;
    display: block;
    height: 41px;
    width: 41px;
  }
  .h-work {
    color: #ffffff !important;
    background: #55ba47;
    border-radius: 50%;
    padding: 8px 10px;
    margin: 0px 10px 0px 0px;
    display: block;
    height: 41px;
    width: 41px;
    font-size: 21px !important;
  }
  .h-er {
    color: #ffffff !important;
    background: #ed1c24;
    border-radius: 50%;
    padding: 8px 10px;
    margin: 0px 10px 0px 0px;
    display: block;
    height: 41px;
    width: 41px;
    font-size: 21px !important;
  }
  .cdc-icon {
    position: relative;
    top: 0px;
    vertical-align: baseline;
  }
  .btntooltip {
    display: inline;
    position: relative;
    margin-right: 10px;
  }
  .btntooltip:hover:before {
    content: attr(data-cctitle) !important;
    background: #0e0e0e;
    border-radius: 0px;
    color: #fff;
    font-size: 16px;
    padding: 13px;
    width: 290px;
    position: absolute;
    bottom: 33px;
    z-index: 1000;
  }
  .btntooltip:hover:after {
    border: solid;
    border-color: #0e0e0e transparent;
    border-width: 12px 6px 0 6px;
    content: '';
    left: 45%;
    bottom: 21px;
    position: absolute;
  }
  //iPhone 6 fix
  @media only screen and (max-width: 320px) {
    .panel-container {
      margin: 0px auto !important;
    }
    .cdc-nav {
      margin: 0px 0px !important;
    }
  }
}

.cert-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1004;
  .ReactModal__Content {
    padding: 0px !important;
    border-width: 0px !important;
  }
  .modal-header {
    display: flex;
    background: #fff !important;
    align-items: flex-start;
    border-color: #fff !important;
    justify-content: space-between;
    padding: 1rem;
    color: #fff;
    align-items: center;
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }
    .no-padding {
      padding: 0px;
    }
    p {
      font-size: 14px;
      padding-top: 1rem;
      color: #000;
    }
    span {
      vertical-align: initial;
    }
    .close {
      padding: 5px 10px !important;
      margin: 0 0 0 auto !important;
      background: #005ead;
      color: #000;
      background: rgba(255, 255, 255, 0.25) !important;
      font-size: 2rem;
      float: right;
    }
    .close:hover {
      color: #000 !important;
    }
  }
  .modal-body {
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem 1rem 1rem;
    color: #000;
    align-items: center;
    p,
    li {
      color: #000000;
      text-align: left;
    }
    table {
      thead {
        background-color: #f0f0f0;
      }
      tbody {
        border: 0px;
        tr:nth-child(even) {
          background-color: #fff;
        }
      }
      font-size: 14px;
      td {
        padding: 1rem 0.625rem;
        vertical-align: top;
        border-top: 0px;
        height: 100%;
      }
      th {
        padding: 9px 10px;
        text-align: left;
        width: 75%;
      }
      .ts0 {
        display: block;
        margin-bottom: 10px;
      }
      .ts1 {
        padding: 60px 0.625rem;
      }
    }
  }
}
