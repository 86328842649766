.attestation-form {
  .button {
    height: 48px;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    .range-value0 {
      padding: 0px 12px 0px 0px !important;
      width: auto !important;
    }
    .help1 {
      p {
        padding: 5px 0px !important;
        text-align: center !important;
      }
    }
  }
  input[type='checkbox'] {
    height: 16px;
    width: 16px;
    min-width: 16px;
    top: 3px;
    position: relative;
  }
  .temp0 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-right: 0rem;
    padding-left: 0rem;
    label.temp-label {
      display: inline-flex;
      margin-right: 1rem;
    }
    .alert {
      margin-bottom: 0px;
      padding: 0.5rem;
    }
    .alert-info {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;
      width: 290px;
    }
    .p-11 {
      font-weight: 500;
      font-size: 1.2rem;
    }
    p {
      text-align: left;
      margin-bottom: 0.5rem;
    }
    input[type='number']::-webkit-inner-spin-button {
      opacity: 1;
    }
  }
  #cdcTemperatureRadio {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .form-no-padding {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .p0 {
    margin-bottom: 0px;
  }
  input#temperature-range {
    margin-top: 1rem;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    input#temperature-range {
      margin-top: 0rem;
      margin-bottom: 0rem;
      padding-bottom: 0rem;
    }
    #rangevalue {
      width: 60px;
      display: flex;
      justify-content: center;
      padding: 3px 5px;
      font-size: 1.1rem;
      border-radius: 0px !important;
      border: 1px solid #ced4da;
      border-right-width: 0px;
      border-left-width: 0px;
      height: 38px;
      margin-bottom: 0px !important;
      text-align: center;
    }
  }
  input[type='range'] {
    -webkit-appearance: none;
    margin: 0px 0;
    width: 100%;
  }
  input[type='range']:focus {
    outline: none;
  }

  @media only screen and (max-width: 768px) {
    input[type='range'] {
      width: 55% !important;
    }
  }
  #rangevalue {
    width: 60px;
    display: flex;
    justify-content: center;
    padding: 3px 5px;
    font-size: 1.1rem;
    border-radius: 0px !important;
    border: 1px solid #ced4da;
    border-right-width: 0px;
    border-left-width: 0px;
    height: 38px;
    margin-bottom: 0px !important;
    text-align: center;
  }
  .temperature-value {
    position: relative;
    color: #aaa;
    font-size: 1rem;
    margin: 0.5rem 0px;
    .wi-fahrenheit {
      position: absolute;
      top: -4px;
      left: 63px;
      font-size: 40px;
      right: 0px;
      color: black;
      width: 2rem;
    }
  }

  .help0 {
    p {
      color: #005ead;
      font-size: 1rem;
      margin: auto;
      padding: 45px 0px;
      cursor: pointer;
      font-weight: 500;
    }
    span {
      margin-right: 0.3rem;
      vertical-align: middle;
      margin: 0px 5px 0px 0px;
      position: relative;
      top: 1px;
    }
  }
  .help1 {
    p {
      color: #005ead;
      font-size: 1rem;
      margin: auto;
      padding: 20px 0px;
      cursor: pointer;
      font-weight: 500;
      text-align: left;
    }
    span {
      margin-right: 0.3rem;
      vertical-align: middle;
      margin: 0px 5px 0px 0px;
      position: relative;
      top: -1px;
    }
  }
  .range-value0 {
    padding: 0px;
    width: 170px;
  }
  .quantity-nav {
    position: absolute;
    left: 89px;
    height: 40px;
    font-size: 24px;
    .quantity-button.quantity-up {
      height: 50%;
      top: 0;
      color: #444;
      display: flex;
      align-items: center;
    }
    .quantity-button.quantity-down {
      height: 50%;
      bottom: 0;
      color: #444;
      display: flex;
      align-items: center;
    }
  }
  .input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .input-group-prepend {
    margin-right: 0px;
  }
  .input-group-append,
  .input-group-prepend {
    display: -ms-flexbox;
    display: flex;
  }
  .input-group-append {
    margin-left: 0px;
  }

  .input-group-append,
  .input-group-prepend {
    display: -ms-flexbox;
    display: flex;
  }
  .temperature-unit-text {
    display: flex;
    align-items: center;
    padding: 3px 6px 3px 0px;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: #0a0a0a;
    text-align: center;
    white-space: nowrap;
    background-color: #fefefe;
    border: 1px solid #ced4da;
    border-radius: 0rem;
    height: 38px;
    border-right-width: 0px;
    border-left-width: 0px;
  }
  .cdc-btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    height: 38px;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  .btn-outline-cdc {
    color: #6c757d;
    border-color: #6c757d;
  }
  .input-group-prepend .cdc-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-append .cdc-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
