@import '../variables';

.required-todo-list {
    display: flex;
    justify-content: center;
    align-items: center;

    .errorMSG {
        color: $errorText;
    }
}