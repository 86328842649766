.spine-provider {
  .spine-card {
    box-shadow: 2px 2px 5px -1px rgba(191, 191, 191, 1);
    width: 21.5em !important;
    border-radius: 0px !important;
    height: 250px;
    h3 {
      color: black;
      font-size: 1em;
    }
    h4 {
      font-size: 14px;
      font-weight: normal !important;
    }

    a {
      font-weight: 500 !important;
    }
  }

  .spine-card-mobile {
    h3 {
      color: black;
      font-size: 1em;
    }
    h4 {
      font-size: 14px;
      font-weight: normal !important;
    }

    box-shadow: 2px 2px 5px -1px rgba(191, 191, 191, 1);
    border-radius: 0px !important;
    width: 100% !important;
    a {
      font-weight: 500 !important;
    }
  }

  .viewMap {
    margin-top: 7px;
    font-size: 14px;
    font-weight: 500 !important;
    padding-left: 2px;
  }

  .spine-distance {
    vertical-align: middle;
    color: #286ce2;
    margin-left: -5px;
    font-size: 10px;
    margin-right: 5px;
  }
}
