@import '../variables';

.agent-wait-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  text-align: center;
  .columns {
    padding-left: 0;
    padding-right: 0;
    div.content {
      width: 370px;
      p {
        font-size: 0.9rem;
        &:first-child {
          margin-top: 20px;
        }
        a {
          color: #2199e8;
          text-decoration: underline;
        }
      }
      p.wait-message {
        padding: 0px 20px;
      }
      img {
        margin: 20px 0px;
      }
      div {
        span {
          font-weight: bold;
        }
      }
    }
  }
}
