.todo-dropdown {
  @media only screen and (max-width: 1023px) {
    .btn-outline-primary {
      height: 92%;
      padding: 13px 10px !important;
      font-weight: bold;
      font-size: 10px !important;
      width: auto !important;
      max-width: 130px;
    }
    #popover-contained {
      left: 4px !important;
    }
  }
  .background-blur {
    background-color: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(2px);
  }
  .todo-button-text {
    pointer-events: none;
  }
  @supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
    .background-blur {
      background-color: rgba(255, 255, 255, 0.8);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }
  #popover-contained {
    top: -9px !important;
    border-radius: 0px;
    width: 100% !important;
    z-index: 3;
    margin-left: 0px !important;
    border: 1px solid #0c4d78;
    ul.link-list {
      margin-top: 0rem;
      background-color: inherit;
    }
    .link-list li a {
      background-image: none;
      background-repeat: no-repeat;
      background-position: center;
      padding: 0px 0px 0px 0px;
      display: inline-block;
      line-height: 1.6;
      color: #0a0a0a;
      font-size: 1.1rem;
    }
    .card {
      background-color: inherit;
      border-radius: 0px;
      border-width: 0px;
    }
    .viewToDos {
      float: right;
    }
  }
  .popover-body {
    padding: 0rem;
    .toDoList {
      margin-bottom: 0rem;
    }

    li {
      display: inline-block !important;
      width: 100%;
      padding: 12px 8px 12px 5px;
      border-top: #c8c8c8 solid thin;
      padding: 16px 16px 16px 16px;
      text-align: left;
    }
  }

  .btn-outline-primary {
    border: 1px solid #0c4d78;
    border-radius: 0;
    color: #0c4d78;
    text-transform: uppercase;
    font-weight: bold;
    padding: 9px 10px;
    height: 100%;
    width: auto !important;
    margin: 1px;
    font-size: 14px;
    background: #fff;
    border-width: 2px !important;
  }

  .btn-outline-primary:focus,
  .btn-outline-primary.focus {
    background-color: #fff !important;
    color: #0c4d78 !important;
    border: 2px solid #0c4d78 !important;
    outline: 0px !important;
    border-radius: 0 !important;
    box-shadow: 0 0 0 0rem transparent !important;
  }
  .btn-outline-primary:hover {
    background-color: #cadef4 !important;
    color: #0c4d78 !important;
    border: 2px solid #0c4d78 !important;
  }
  .popover {
    .arrow {
      display: none;
    }
    .arrow::before,
    .arrow::after {
      display: none;
    }
  }
}
