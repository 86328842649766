.full-details {
  margin: 0px auto;
  width: 100%;
  @media only screen and (max-width: 990px) {
    text-align: left;
    .row {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
    .col-md-12 {
      padding-right: 10px;
      padding-left: 10px;
    }
    button {
      margin: 15px auto;
      width: 94% !important;
      display: block;
      border-radius: 0px;
    }
    .procedures0 {
      position: inherit;
      bottom: 0px !important;
      left: 0px !important;
    }
    .procedure-body {
      bottom: 0px !important;
    }
    .table-header {
      bottom: 0px !important;
      .col {
        padding-right: 0px !important;
        padding-left: 0px !important;
      }
    }
    .procedures2 {
      .col {
        padding-right: 0px !important;
        padding-left: 0px !important;
      }
    }
    .more-procedures {
      bottom: 0px !important;
      left: 0px !important;
      padding-left: 5px !important;
    }
    .cm6 {
      padding-right: 0px;
      padding-left: 0px;
    }
    .accepting-patients {
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
    .procedures1 {
      bottom: 0px !important;
    }
  }
  //end of mobile styles

  .procedures0 {
    position: relative;
    bottom: 135px;
    font-size: 14px;
    color: #000;
    left: -14px;
  }
  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .procedures1 {
    margin: 0px auto;
    width: 100%;
    height: auto;
    z-index: 1;
    position: relative;
    justify-content: center;
    bottom: 90px;
  }
  .procedure-body {
    position: relative;
    bottom: 0px;
  }
  .more-procedures {
    font-size: 12px;
    color: gray !important;
    padding-left: 24px;
    padding-top: 5px;
    position: relative;
    bottom: 3px;
    left: 10px;
  }
  .table-header {
    position: relative;
    bottom: 0px;
    text-align: left;
    font-weight: 400;
    strong {
      font-size: 12px;
      color: grey;
      font-weight: 400 !important;
    }
  }
  .no-padding {
    padding-right: 0px;
    padding-left: 0px;
  }
  button {
    width: 100%;
    font-weight: 600;
    color: #005ead;
    font-size: 14px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    background-color: #fff;
  }
  .heading {
    font-size: 12px !important;
    color: gray !important;
    margin-top: 5px;
  }
  .accepting-patients {
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 10px;
    font-weight: 400;
    color: black;
  }
  .mdcc0 {
    color: #7cbe00;
    font-size: 10px;
  }
  span,
  p {
    font-size: 14px !important;
  }
  .cm6 {
    display: table-cell;
    font-size: 14px !important;
    color: #000 !important;
    .col {
      padding: 0px;
      margin-bottom: 1rem;
    }
    p {
      font-size: 14px;
      color: #000;
      margin-bottom: 0px;
    }
  }
}
