//upswing
.upswing {
  @media only screen and (max-width: 990px) {
    padding: 1rem 0.5rem !important;
    p {
      padding-right: 0rem !important;
      text-align: left;
    }
    h4,
    a,
    .cta,
    .cta-telephone {
      text-align: left;
    }
  }
  word-wrap: break-word;
  background-color: #f2fafc;
  background-clip: border-box;
  border: 1px solid #00a5d8;
  border-radius: 0rem;
  margin-bottom: 0.5rem;
  padding: 1rem 2rem;

  h3 {
    font-size: 14px;
    font-weight: 600;
    color: #00a5d8;
    text-align: left;
  }
  h4 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
  }
  p {
    font-size: 14px;
    padding-right: 9rem;
  }
  a {
    font-size: 14px;
    font-weight: bold;
    color: #00a5d8;
  }
  a:hover {
    color: #00a5d8 !important;
  }
  .contact {
    color: #000;
  }
  .cta {
    margin-bottom: 0.5rem;
  }
}
