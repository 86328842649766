@mixin transition($e) {
  -webkit-transition: $e;
  -moz-transition: $e;
  -o-transition: $e;
  transition: $e;
}

@mixin modal-overlay-deafult {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
}

@mixin modal-default {
  transform: translate(50%, 15%);
  width: 50%;
}
