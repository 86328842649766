.cost-estimator-legend {
  color: #000;
  font-size: 14px;
  .card {
    margin: 0px auto;
    padding: 20px;
  }
  margin-bottom: 1rem;
  p {
    font-size: 14px;
    text-align: left;
    font-weight: 500;
  }
  .desc {
    padding-left: 25px;
  }
  .star {
    color: #ff9800;
    font-size: 16px;
    margin-right: 5px;
  }
  .trophy {
    color: #949494;
    margin-right: 5px;
  }
  h4 {
    font-size: 14px;
    text-align: left;
    font-weight: 600;
  }
  .legend {
    @media only screen and (max-width: 990px) {
      float: none;
    }
    display: flex;
    float: right;
    margin-top: 2rem;
    margin-bottom: 4rem;
    .lg1 {
      margin-left: 25px;
    }
  }
}
