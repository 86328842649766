.incentivesContainer .wellbeing.subpageHeader {
  background: #00a161;
  border-color: #00a161;
}
.requiredKey {
  float: right;
}

#ProgSummArea {
  max-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.programInfoCard.card .dropdown-menu {
  z-index: 998;
}

/*Change look of tabs*/

#myPointsComponent .nav-tabs .nav-link {
  border-right: 2px solid white;
  border-top: none;
  border-left: none;
  border-bottom: none;
}

#myPointsComponent .nav-tabs .nav-link.active {
  border-top: 4px solid #a2afb6;
  border-left: 4px solid #a2afb6;
  border-right: 4px solid #a2afb6;
  border-bottom: none;
  border-radius: 24px 24px 0 0;
}

#myPointsComponent .nav-tabs .nav-link:hover {
  color: #ffffff;
}
#myPointsComponent .nav-tabs .nav-link.active:hover {
  background-color: #ffffff;
  color: #495057;
}

#ProgSummArea a {
  text-decoration: underline;
  color: #ffffff;
}

.mobileHelper {
  display: none;
}

.smallTitle {
  text-align: left;
}

.smallTitle h4 {
  font-size: 1.2rem;
  display: inline-block;
  background-color: #00a161;
  width: 200px;
  margin-bottom: 4px;
  font-weight: normal;
}

.row.linkHolders {
  border: 1px solid white;
}

.incentivesContainer .collapse .selectDateRow > div a {
  pointer-events: none;
}

.incentivesContainer .hideThis {
  display: none !important;
}

.incentivesContainer .tierIndicator {
  display: none;
}

.incentivesContainer .points-meter figure {
  margin: 0;
  width: 225px;
  height: 225px;
}

.incentivesContainer .points-meter .ring-text .ring-label {
  padding: 0 20px;
  font-size: 28px;
}

.incentivesContainer .points-meter .ring-text {
  margin-top: -225px;
}

.incentivesContainer .points-meter .ring-text .ring-number {
  font-size: 72px;
}

.incentivesContainer .progressCircle {
  display: none;
}

/*eND tEMPORARY STUFF*/

body .incentivesContainer {
  font-size: 16px;
}

.incentivesContainer .mobileHeader {
  display: none;
}

.incentivesContainer .wellnessLogo {
  display: inline-block;
  margin-right: 20px;
  max-height: 50px;
  max-width: 200px;
}

.incentivesContainer .dividingRule {
  border-color: #ffffff;
}

.incentivesContainer .programInfoCard legend {
  font-size: 1.2rem;
  font-weight: normal;
  margin-bottom: 0;
}

.incentivesContainer .alert-warning {
  color: #856404 !important;
  background-color: #fff3cd !important;
  border-color: #ffeeba !important;
}

.incentivesContainer .btn.btn-primary {
  background-color: #009ee2;
  border-color: #009ee2;
  background-image: url('images/viewDetailsIcon.png'), url('images/caratIcon.png');
  background-position: 0 center, right center;
  background-repeat: no-repeat;
  padding: 6px 32px 6px 32px;
  width: auto;
}

.incentivesContainer .linkAppearance .btn.btn-primary,
.incentivesContainer .linkAppearance .btn.btn-primary:hover {
  background-color: transparent;
  border: none;
  background-image: none;
  color: #009ee2;
}

.incentivesContainer .btn.btn-primary:hover {
  background-color: #005dac;
  border-color: #005dac;
}

.incentivesContainer .btn.btn-primary.scheduleApptBtn {
  background-image: url('images/scheduleApptIcon.png'), url('images/caratIcon.png');
}

.incentivesContainer .btn.btn-primary.viewDetailsBtn {
  background-image: url('images/viewDetailsIcon.png'), url('images/caratIcon.png');
}

.incentivesContainer .btn.btn-primary.inProgressBtn {
  background-image: url('images/inProgressIcon.png'), url('images/caratIcon.png');
}

.incentivesContainer .levelCard hr {
  border-color: white;
}

.incentivesContainer img.finishLogo {
  display: inline-block;
  width: 100%;
  display: none;
}

/*Hide elements in the My Points Widget*/
.incentivesContainer .incentiveWidg.card-divider,
.incentivesContainer .summary-panel p,
.incentivesContainer .summary-panel .button.hollow {
  display: none;
}

.incentivesContainer .headerContainer .linked.card.shadow,
.incentivesContainer .headerContainer .points-meter {
  padding: 0;
  box-shadow: none !important;
}

.incentivesContainer .headerContainer .points-meter {
  width: 180px;
}

.incentivesContainer .incentiveWidg .summary-panel .required-todos {
  display: none;
}

.incentivesContainer .cardGroupContainer {
  position: relative;
}

.incentivesContainer .cardGroupContainer .card {
  border: none;
}

.incentivesContainer .cardGroupContainer .topCardHeadline {
  color: #009ee2;
  font-size: 2.4rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
}

.incentivesContainer .card.programInfoCard fieldset {
  margin-bottom: 0.75rem;
}

.incentivesContainer .border.p-2 {
  background: #1d4477;
}

.incentivesContainer .reqHolder {
  float: right;
  font-size: 1rem;
  color: #000000;
}

.incentivesContainer .badge.req {
  background-color: #f39e1a;
  border-radius: 50%;
  font-size: 1.2rem;
  display: inline-block;
  margin-left: 10px;
  padding: 0;
  width: 28px;
  height: 28px;
  line-height: 28px;
}

span.requiredActivity {
  background-image: url('images/requiredIcon.png');
  background-position: top right;
  background-repeat: no-repeat;
  line-height: 28px;
  padding-right: 90px;
  display: inline-block;
}

.incentivesContainer .programInfoCard .topCardHeadline {
  font-size: 1.4rem;
}

.incentivesContainer .cardGroupContainer .card-body {
  padding: 1rem 2rem;
  text-align: center;
}

.incentivesContainer .cardGroupContainer .programInfoCard .topCardHeadline {
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.incentivesContainer .pointsColumn {
  font-weight: bold;
  color: #009ee2;
  font-size: 1.4rem;
}

.incentivesContainer .ptsEarnedCol {
  font-weight: bold;
  color: #00a161;
  font-size: 1.4rem;
}

.incentivesContainer .pointsColumn .maxPts {
  display: block;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 0.8rem;
  font-weight: normal;
  font-style: italic;
  color: #a2afb6;
}

.incentivesContainer .pointsColumn .maxPts span {
  font-weight: bold;
}

.incentivesContainer {
  background-image: url('images/MyPointsBAR.jpg');
  background-position: -780px 0;
  background-repeat: no-repeat;
  padding: 20px;
  padding-left: 380px;
  background-attachment: fixed;
  background-size: initial;
  min-height: 973px;
}

.incentivesContainer .headerContainer h1 {
  font-size: 2rem;
  font-weight: normal;
  margin-bottom: 20px;
  line-height: 50px;
}

.incentivesContainer .headerContainer h1 img {
  vertical-align: bottom;
}

.incentivesContainer .headerContainer h1 span {
  font-weight: bold;
}

.incentivesContainer .nav {
  font-size: 1.2rem;
  background: #a2afb6;
  color: #273137;
}

.incentivesContainer .nav-link {
  color: #273137;
  padding: 1rem;
  font-weight: bold;
}

.incentivesContainer .nav-tabs .nav-link:hover {
  background: #999999;
  color: #222222;
}

/*Header Section*/

.incentivesContainer .headerContainer {
  padding-left: 90px;
  margin-bottom: 20px;
}
.incentivesContainer .headerContainer .card-group {
  color: #ffffff;
  background-image: linear-gradient(to right, #1d4477, #005dac);
  margin-left: 120px;
  padding-left: 90px;
  min-height: 180px;
}

.incentivesContainer .headerContainer p {
  font-size: 1rem;
}

.incentivesContainer .headerContainer p.singleTierProgram {
  margin-top: 30px;
}

.incentivesContainer .headerContainer .singleTierProgram .leadText {
  font-size: 1.4em;
  margin-bottom: 8px;
}

.incentivesContainer .column.row.subpageHeader {
  margin-bottom: 0;
}

.incentivesContainer .headerContainer .card-group .card {
  background: transparent;
  overflow: visible;
}

.incentivesContainer .headerContainer .linked.card.shadow {
  position: absolute;
  z-index: 2;
  background: transparent;
}

.incentivesContainer .circleBack {
  position: absolute;
  z-index: 1;
  width: 225px;
  height: 225px;
  background: #009ee2;
  border-radius: 50%;
  margin-top: -20px;
  top: 5px;
}

.incentivesContainer .card.incentiveWidgCard {
  margin-top: -20px;
}

.incentivesContainer .points-meter figure .figure-content svg {
  height: 225px !important;
  width: 225px !important;
}

.incentivesContainer .headerContainer .points-meter {
  width: 225px;
  height: 225px;
}

.incentivesContainer .progressCircle {
  stroke: #005dac !important;
}

.incentivesContainer .guideCircle {
  stroke: #1d4477 !important;
}

.incentivesContainer .points-meter .ring-text .ring-label,
.incentivesContainer .points-meter .ring-text .ring-number {
  color: #ffffff !important;
}

.incentivesContainer .headerContainer .card-title.h5 {
  color: #ffffff;
  margin-bottom: 0.25rem;
}

.incentivesContainer .headerContainer p {
  color: #ffffff;
}

.incentivesContainer .tabContent h4 {
  color: #00a161;
  margin-bottom: 12px;
  font-weight: 800;
}

.incentivesContainer .linksBar a:visited {
  color: #ffffff;
}

.incentivesContainer .changeScenarios button {
  width: auto;
  margin: 20px 20px 20px 0;
}

.incentivesContainer .headerContainer {
  padding: 20px 0;
}

.incentivesContainer .programInfoCard {
  overflow: visible;
}

/* .pointsGrid .container .row > .col:first-child {
  flex: 0 0 50%;
  max-width: 50%;
} */

.incentivesContainer .prevYrButton {
  float: right;
}

.incentivesContainer .prevYrButton .btn-link {
  color: #009ee2;
  background: white;
}

.incentivesContainer .prevYrButton .btn-link:hover {
  color: #009ee2;
}

.incentivesContainer .tabContent {
  padding: 20px;
}

.incentivesContainer .totalRow.row {
  font-size: 1.6rem;
  background: #00a161 !important;
  color: #ffffff !important;
}

.incentivesContainer .totalRow.row .pointsColumn {
  color: #ffffff !important;
}

.incentivesContainer .incentivesContainer .totalRowBottom {
  font-size: 1.6rem;
  background: #273137 !important;
  color: #ffffff !important;
}

.incentivesContainer .nav-tabs {
  border-bottom: none;
}

.incentivesContainer .incentivesContainer .card {
  margin-bottom: 0;
}

/* #ProgSummArea {
  max-height: 40px;
  overflow: hidden;
} */

.incentivesContainer .programSummary p,
.incentivesContainer .programSummary ul {
  font-size: 1.2rem;
}

.incentivesContainer a.pdfIconPS {
  background-image: url('images/linkIcon.png');
  background-repeat: no-repeat;
  background-position: 3px center;
  padding: 10px 10px 10px 36px;
  display: inline-block;
  line-height: 36px;
  color: #ffffff;
}

.incentivesContainer a.pdfIconPS.pdf {
  background-image: url('images/pdfIcon.png');
}

.incentivesContainer a.pdfIconPS.docx {
  background-image: url('images/docIcon.png');
}

.incentivesContainer a.pdfIconPS:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #007244;
}

.incentivesContainer #uncontrolled-tab-example-tab-programSummary.active,
#myPointsComponent .incentivesContainer #uncontrolled-tab-example-tab-programSummary.active:hover {
  background: #00a161;
  color: #ffffff;
  border-bottom: none;
}

.incentivesContainer .programSummary .linksBar {
  background: #00a161;
  color: #ffffff;
}

.programSummary .container div,
.programSummary .container div span,
.programSummary .container div p {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 1em !important;
}

.programSummary .container.programSummaryArea a,
.programSummary .container.programSummaryArea a span {
  color: #009ee2;
  text-decoration: underline;
}

.incentivesContainer .tooltipTrigger.btn.btn-link {
  text-align: left;
}

/* .tooltip,
.tooltip-inner {
  width: 280px !important;
  max-width: 280px !important;
  background-color: #1d4477 !important;
} */

.incentivesContainer button.btn.tooltipTrigger {
  border-bottom: 1px dotted #8a8a8a;
  padding: 0;
  font-weight: 700;
  color: #707070;
  text-decoration: none;
}

.incentivesContainer h1 .endDate {
  font-weight: normal;
  font-size: 0.9rem;
  font-style: italic;
}

.incentivesContainer table.table {
  margin: 10px 0 30px 0;
}

.incentivesContainer table tr td,
.incentivesContainer table thead th {
  text-align: center;
}

.incentivesContainer table tr td:first-child,
.incentivesContainer table th:first-child {
  width: 40%;
  text-align: left;
}

.incentivesContainer table .footer td {
  font-weight: bold;
  font-size: 1.2rem;
}

.incentivesContainer .alert-primary {
  color: #004085 !important;
  background-color: #cce5ff !important;
  border-top: none !important;
}

/*Table and subtable styling*/

.incentivesContainer .pointsGrid .row {
  border-top: 1px solid #dee2e6;
  margin-left: -15px;
  margin-right: -15px;
}

.incentivesContainer .pointsGrid > .row:nth-child(odd),
.incentivesContainer .earnPointsGrid > .container > .row:nth-child(odd) {
  background-color: #eff2f4;
}

.incentivesContainer .pointsGrid .row > div {
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.incentivesContainer .pointsGrid .row > div.col {
  border-top: none;
}

.incentivesContainer .pointsGrid .accordion {
  background: transparent;
}

.incentivesContainer .pointsGrid .accordion > .row {
  border-top: none;
  margin: 0;
}

.incentivesContainer .pointsGrid .accordion > .row > div {
  border-top: none;
  padding: 0;
}

.incentivesContainer .collapse {
  padding: 16px;
}

.incentivesContainer .collapse > .container {
  background-color: #ffffff;
  padding: 0;
}

.incentivesContainer .collapse > .container .row {
  margin: 0;
}

.incentivesContainer .collapse > .container .row:nth-child(odd) {
  background-color: #dcecf9;
}

.incentivesContainer .collapse > .container .row.selectDateRow {
  background-color: #005dac;
  color: #ffffff;
  padding: 5px 10px;
}

.incentivesContainer .collapse .selectDateRow > div {
  text-align: center !important;
  border-top: none;
  padding: 0;
}

.incentivesContainer .collapse .selectDateRow > div a {
  color: #ffffff;
}

.incentivesContainer .collapse .selectDateRow > div a:hover {
  color: #e6e6e6;
}

.incentivesContainer .collapse .selectDateRow > div.active a {
  color: #009ee2;
  font-weight: bold;
}

.incentivesContainer .collapse > .container .row.headerRowCollapse {
  background-color: #1d4477;
  color: #ffffff;
  font-weight: bold;
}

.incentivesContainer .pointsGrid .row > div:first-child {
  text-align: left;
}

.incentivesContainer .pointsGrid > .row:first-child {
  background-color: #a2afb6;
  color: #ffffff;
  font-weight: bold;
}

.incentivesContainer .topCardHeadline {
  font-weight: bold;
  font-size: 2rem;
  display: block;
}

.incentivesContainer .pointsGrid > .container {
  /* border-left: 2px solid #77bd43; */
  border-bottom: 2px solid #77bd43;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 60px;
}

/* .pointsGrid > .container .row:first-child {
  background-color: #a2afb6;
  color: #ffffff;
  font-weight: bold;
} */

.incentivesContainer .pointsGrid button.btn.link {
  padding: 0;
}

.incentivesContainer .totalRow {
  font-weight: bold;
}

.incentivesContainer .programSummary {
  padding: 0;
}

.incentivesContainer .programSummary h1 {
  font-size: 1.8rem;
  color: #009ee2;
  margin-bottom: 1rem;
}

.incentivesContainer .programSummary p {
  margin-bottom: 1rem;
}

.incentivesContainer .levelIndicator .col:first-child {
  text-align: left;
}

.incentivesContainer .levelIndicator {
  background-image: url('images/dotBG.png');
  background-repeat: repeat-x;
  background-position: center left;
}

.incentivesContainer .levelIndicator.singleLevelProgram {
  display: none;
}

.incentivesContainer .levelIndicator.row {
  margin: 20px 20px 0 20px;
}

.incentivesContainer .levelIndicator .col {
  text-align: center;
  padding: 0;
}

.incentivesContainer .levelIndicator .col:last-child {
  text-align: right;
}

.incentivesContainer .levelIndicator .levelIndic {
  display: inline-block;
  margin: 0 auto;
  padding: 0;
  border-radius: 50%;
  background: #005dac;
  width: 38px;
  height: 38px;
  line-height: 34px;
  text-align: center;
  color: #1d4477;
  border: 2px solid #1d4477;
  box-sizing: border-box;
  font-weight: bold;
}

.incentivesContainer .levelIndicator .levelIndic.active {
  background: #009ee2;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.incentivesContainer .levelIndicator .col.highestAchieved + .col .levelIndic {
  background: #009ee2;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.incentivesContainer .levelIndicator .col.highestAchieved + .col.highestAchieved .levelIndic {
  background: #005dac;
  color: #1d4477;
  border: 2px solid #1d4477;
}

@media only screen and (max-width: 1296px) {
  .incentivesContainer {
    background-position: -1000px 0;
    padding-left: 160px;
    background-size: auto;
  }
  .incentivesContainer .cardGroupContainer .programInfoCard .topCardHeadline {
    font-size: 1.2rem;
  }

  .incentivesContainer .card.incentiveWidgCard {
    margin-top: -10px;
  }

  .incentivesContainer .circleBack {
    margin-top: -10px;
  }
}

@media only screen and (max-width: 1023px) {
  .incentivesContainer .btn.btn-primary,
  .incentivesContainer .btn.btn-primary.scheduleApptBtn,
  .incentivesContainer .btn.btn-primary.viewDetailsBtn,
  .incentivesContainer .btn.btn-primary.inProgressBtn {
    padding: 6px;
    background-image: none;
    width: auto;
    width: 120px;
  }

  #myPointsComponent .nav-tabs .nav-link {
    border-right: 1px solid white;
    border-top: none;
    border-left: none;
    border-bottom: none;
  }

  #myPointsComponent .nav-tabs .nav-link.active {
    border-top: 1px solid #a2afb6;
    border-left: 1px solid #a2afb6;
    border-right: 1px solid #a2afb6;
    border-bottom: none;
  }

  .incentivesContainer {
    background-image: none;
    padding-left: 20px;
  }

  .incentivesContainer .levelIndicator.row {
    display: none;
  }

  .incentivesContainer .headerContainer .points-meter {
    margin: 5px auto;
  }

  .incentivesContainer .nav {
    font-size: 1rem;
  }

  .incentivesContainer .headerContainer h1 {
    font-size: 1.6rem;
  }

  .incentivesContainer .headerContainer h1 img {
    width: 100px;
  }

  .incentivesContainer .cardGroupContainer .card-group .card {
    width: 100%;
  }

  .incentivesContainer .cardGroupContainer .topCardHeadline {
    color: #ffffff;
  }

  .incentivesContainer .levelCard .h5.card-title,
  .incentivesContainer .programInfoCard p p,
  .incentivesContainer .prevYrButton {
    display: none;
  }

  .incentivesContainer .cardGroupContainer {
    background-image: url('images/MyPointsBAR.jpg');
    background-repeat: no-repeat;
    background-position: center right;
    height: auto;
  }
  .incentivesContainer .headerContainer .card-group {
    background-image: none;
    margin-left: 0;
    padding-left: 0;
    min-height: initial;
  }

  .incentivesContainer .programInfoCard.card {
    text-align: right;
  }

  .incentivesContainer .headerContainer .linked.card.shadow {
    position: relative;
    margin-top: -225px;
  }

  .incentivesContainer .circleBack {
    position: relative;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .mobileHelper {
    display: inline;
  }

  .incentivesContainer {
    padding: 10px;
  }

  .programSummary .linksBar .linkHolders.row .col,
  .programSummary .linksBar .linkHolders.row .col a {
    width: 100%;
  }

  .incentivesContainer .nav-tabs .nav-link {
    font-size: 14px;
    padding: 1rem 0.5rem;
  }
  .incentivesContainer .tabContent {
    padding: 15px 0;
  }

  .incentivesContainer .points-earned .btn.btn-primary {
    width: auto;
  }

  .incentivesContainer .points-earned .collapse {
    padding: 0;
  }

  .incentivesContainer .points-earned .collapse .col-md-5,
  .incentivesContainer .points-earned .collapse .col-md-3,
  .incentivesContainer .points-earned .collapse .col-md-2 {
    width: 25%;
    text-align: center;
  }

  .incentivesContainer .headerContainer h1 {
    font-size: 1.4rem;
  }

  .incentivesContainer .programInfoCard,
  .incentivesContainer .levelCard {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }

  .incentivesContainer .programInfoCard.card {
    text-align: center;
  }

  .incentivesContainer .levelCard .card-body,
  .incentivesContainer .programInfoCard .card-body {
    padding: 0;
  }

  .incentivesContainer .row.tableHeadRow {
    display: none;
  }
  .incentivesContainer .mobileHeader {
    display: inline;
    font-weight: bold;
    color: #000000;
  }

  .incentivesContainer .pointsGrid.earnPointsGrid .row > div:first-child,
  .incentivesContainer .pointsGrid .row > div:first-child {
    text-align: center;
  }

  .incentivesContainer .deadlineOver {
    display: none;
  }
  .incentivesContainer .pointsGrid .row > div {
    border: none !important;
    padding: 0.5rem;
  }
  .incentivesContainer .pointsGrid .row > div:first-child {
    padding: 0.75rem 0.25rem 0.5rem;
  }
  .incentivesContainer .pointsGrid .collapse .row > div:first-child {
    width: 50%;
  }
  .incentivesContainer .pointsGrid .row > div:last-child {
    padding: 0.25rem 0.25rem 0.75rem;
  }

  .incentivesContainer .outcomeColPE,
  .incentivesContainer .dateColPE {
    display: none;
  }
}
.alert-secondary {
  color: #383d41 !important;
  background-color: #e2e3e5 !important;
  border-color: #d6d8db !important;
}
.alert-danger {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}
.prevYrButton {
  display: block !important;
}
.errorToDoLoad {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin: 1rem;
  border: 1px solid transparent !important;
  border-radius: 0.25rem;
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
  box-shadow: 0px 0px 0px 0px #ccc !important;
  transition: opacity 0.15s linear;
}

@media only screen and (max-width: 519px) {
  .incentivesContainer .nav-tabs .nav-link {
    font-size: 12px;
    padding: 1rem 0.25rem;
  }
}
