@import '../variables';

.chat-view-component {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.test {
  flex: 0 0 50px;
  background-color: red;
}

div.agent-name-time {
  flex: 0 0 30px;
  background-color: #f3f3f3;
  color: #868686;
  text-align: center;
  line-height: 30px;
  &.error {
    background-color: #ffe7e7;
    color: #d40000;
    font-weight: 600;
  }
}

div.chat-window {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 323px;

  div.wrapper {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    animation-name: fadeIn;
    animation-duration: 1s;

    div.info {
      max-width: 245px;
      display: flex;
      flex-direction: row;

      &.user {
        align-self: flex-end;
        margin-right: 20px;
      }

      &.agent {
        margin-left: 20px;
      }

      p {
        margin-bottom: 0px;
        color: #878787;
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
    div.msg {
      max-width: 255px;
      word-wrap: break-word;
      margin-bottom: 10px;
      line-height: 24px;
      p {
        margin-bottom: 0px;
      }
    }
  }

  .info-message {
    align-self: center;
  }

  .user-bubble {
    margin-right: 15px;
    position: relative;
    padding: 10px 20px;
    background: #34a5e0;
    border-radius: 10px;
    align-self: flex-end;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 70%;
      width: 0;
      height: 0;
      border: 13px solid transparent;
      border-left-color: #34a5e0;
      border-right: 0;
      border-bottom: 0;
      margin-top: -6.5px;
      margin-right: -13px;
    }
    p {
      color: white;
    }
  }

  .ha-bubble {
    position: relative;
    margin-left: 15px;
    padding: 10px 20px;
    background: #e5e5ea;
    border-radius: 10px;
    color: black;
    align-self: flex-start;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 70%;
      width: 0;
      height: 0;
      border: 13px solid transparent;
      border-right-color: #e5e5ea;
      border-left: 0;
      border-bottom: 0;
      margin-top: -6.5px;
      margin-left: -13px;
    }

    &.typing {
      padding: 1px 15px;
      height: 40px;

      span {
        font-size: 3.5rem;
        line-height: 40px;
        color: #878787;
        opacity: 0;
        -webkit-animation: ellipsis-dot 1s infinite;
        animation: ellipsis-dot 1s infinite;

        &:nth-child(1) {
          -webkit-animation-delay: 0s;
          animation-delay: 0s;
        }
        &:nth-child(2) {
          -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
        }
        &:nth-child(3) {
          -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
        }
      }
    }
  }
}

div.bottom-reply {
  background-color: #d5d5d5;
  border: 1px solid #aca9a7;
  flex: 0 0 60px;
  display: flex;
  flex-direction: row;

  form {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    .input-group {
      margin: 0px 10px 0px;
      width: 378px;
      @media screen and (max-width: 450px) {
        width: 350px;
      }
    }

    .send-message {
      @extend %buttonStyle;
      margin-left: 10px;
      min-width: 60px;
      &:active {
        padding: 0.7em 0.9em;
      }
    }
  }
}

// animations
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ellipsis-dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
