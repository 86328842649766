@import '../../variables';

$disclaimer-text-color: #999999;

// extends //
%ring-rumber-alt {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: inherit;
}

.my-points-title {
  text-align: center;
  color: #2d383f !important;
}

.summary-panel {
  .disclaimer-text {
    font-size: 0.8em;
    color: $disclaimer-text-color;
    font-style: italic;
    text-align: center;
    padding: 10px;
  }
  .required-todos {
    padding: 0 16px;

    .required-todo-list .link-list {
      margin-top: 0;

      p,
      a {
        font-size: 1rem;
      }

      .points.label,
      .small.label {
        font-size: 14px;
      }

      a {
        div {
          margin: 4px 0;
        }
      }
    }
  }

  .button.hollow {
    margin: 0;
  }
}

.spin-container {
  @extend %ring-rumber-alt;
}

.error-container {
  @extend %ring-rumber-alt;
  color: $errorText;

  span {
    text-align: center;
  }
}
