@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

#prescriptionDiscountCardComponent h1,
#prescriptionDiscountCardComponent h2,
#prescriptionDiscountCardComponent h3,
#prescriptionDiscountCardComponent h4,
#prescriptionDiscountCardComponent p {
  font-family: 'Lato', sans-serif;
}

#prescriptionDiscountCardComponent h3 {
  font-size: 1.3rem;
  font-weight: bold;
}

#prescriptionDiscountCardComponent {
  font-family: 'Lato', sans-serif;
  /*font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;*/
  color: #283339;
}

// canvas {
//   left: -999px;
//   bottom: -999px;
// }

.rxDiscountPage {
  background-image: url(img/circleImage.jpg);
  background-repeat: no-repeat;
  background-position: right top;
  padding: 20px 600px 20px 2px;
}

.modal .modal-body img.scissorOne {
  position: absolute;
  left: 60px;
  top: 0;
  margin: 0;
}

.modal .modal-body .foldLine {
  position: absolute;
  right: -30px;
  top: 50%;
  font-weight: bold;
  color: #a2afb6;
}

.modal .modal-body img.scissorTwo {
  position: absolute;
  right: 0;
  bottom: 60px;
  margin: 0;
}

#capture .btn-primary#printCardBtn {
  display: none;
  margin: 40px auto;
}

img.hpLogo {
  display: block;
  float: right;
  margin-top: 0;
  width: 260px;
  height: auto;
}

.modal .modal-body img.rxCardBackground {
  position: absolute;
  top: 0;
  right: 0;
  height: 236px;
  width: auto;
  z-index: 1;
  margin: 0;
}

.RxFooterText {
  margin-top: 40px;
}

.RxFooterText .footerLeft {
  padding-right: 300px;
  text-align: left;
  font-size: 12px;
}

.RxFooterText a.androidIcon {
  width: 32px;
  height: 38px;
  background-image: url(img/androidLink.png);
  background-repeat: no-repeat;
  background-position: left top;
  display: inline-block;
}

.RxFooterText a.iosIcon {
  width: 32px;
  height: 38px;
  background-image: url(img/applLink.png);
  background-repeat: no-repeat;
  background-position: left top;
  display: inline-block;
}

.RxFooterText .haHeartcon {
  width: 46px;
  height: 38px;
  background-image: url(img/haHeart.png);
  background-position: left top;
  display: inline-block;
}

#prescriptionDiscountCardComponent h1 {
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 20px;
}

#prescriptionDiscountCardComponent h3 {
  color: #009ee2;
}

.prescriptionDiscountCard.modal .modal-body .rxCardFront h1 {
  color: #009ee2;
  text-align: left;
  font-size: 2em;
  z-index: 20;
  position: relative;
}

.prescriptionDiscountCard.modal .modal-body .RxFooterText {
  color: #bac3c8;
}

.prescriptionDiscountCard .modal-header {
  background: #009ee2;
}

#capture {
  padding: 20px;
}

#prescriptionDiscountCardComponent .btn-primary,
#capture .btn-primary {
  background-color: #f47721;
  border-color: #f47721;
  border-radius: 36px;
  font-size: 1.3rem;
  font-weight: normal;
  margin: 20px 0;
  padding: 0.375rem 1.5rem;
}

.modal .modal-body p.centerP {
  text-align: center;
}

.infoSection {
  padding-left: 80px;
  position: relative;
  min-height: 72px;
  margin-bottom: 20px;
}

.infoSection .infoSecIcon {
  background-image: url(img/rxIcons.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 72px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.infoSection.sectionTwo .infoSecIcon {
  background-position: 0 -88px;
}

.infoSection.sectionThree .infoSecIcon {
  background-position: 0 -186px;
}

.prescriptionDiscountCard .modal-dialog {
  width: 100%;
  max-width: 612pt;
}

.prescriptionDiscountCard .cardHolder {
  position: relative;
  width: 410px;
  height: 472px;
  margin: 20px auto;
  padding: 16px;
  box-sizing: border-box;
}

.prescriptionDiscountCard .modal-content .rxName {
  padding: 0;
  text-align: left;
  font-size: 14px;
  z-index: 10;
  position: relative;
}

.prescriptionDiscountCard .modal-content .rxGroup {
  padding: 0;
  text-align: left;
  font-size: 14px;
  z-index: 10;
  position: relative;
}

.prescriptionDiscountCard .modal-content .rxBIN {
  padding: 0;
  text-align: left;
  font-size: 14px;
  z-index: 10;
  position: relative;
}

.prescriptionDiscountCard .modal-content .rxPCN {
  padding: 0;
  text-align: left;
  font-size: 14px;
  z-index: 10;
  position: relative;
}

.prescriptionDiscountCard .modal-content .rxmemNum {
  padding: 0;
  text-align: left;
  font-size: 14px;
  z-index: 10;
  position: relative;
}

p.disclaimerTextRx {
  margin: 20px 0;
  font-size: 12px;
}

.prescriptionDiscountCard .modal-content .rxCardFrontFine {
  width: 200px;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  z-index: 10;
  position: relative;
}

.prescriptionDiscountCard .modal-content .rxCardFront {
  position: relative;
  width: 378px;
  height: 236px;
  margin: 0;
  border: 1px dotted #a2afb6;
  background: #ffffff;
  overflow: hidden;
  border-radius: 16px;
  padding: 10px;
  text-align: left;
  box-sizing: border-box;
}

.modal .modal-body img.haLogoCard {
  margin: 8px 0;
  z-index: 20;
  position: relative;
}

.prescriptionDiscountCard .modal-content .rxCardBack {
  position: relative;
  transform: rotate(180deg);
  width: 378px;
  height: 236px;
  border: 1px dotted #a2afb6;
  background: #ffffff;
  border-radius: 16px;
  padding: 10px;
  box-sizing: border-box;
}

.prescriptionDiscountCard .modal-content .rxCardBack .rxCardBackFine {
  font-size: 10px;
  line-height: 12px;
}

.prescriptionDiscountCard .modal-content .rxCardBack h3,
.prescriptionDiscountCard .modal-content .rxCardBack h4 {
  text-align: center;
  color: #009ee2;
}

.prescriptionDiscountCard .modal-content .rxCardBack h3 {
  margin: 30px 0;
}

.prescriptionDiscountCard .modal-content .rxCardBack h4 {
  margin-bottom: 20px;
}

.prescriptionDiscountCard .modal-content .rxPhone {
  position: absolute;
  top: 300px;
  left: 100px;
  z-index: 20;
  background-color: white;
  padding: 0;
  text-align: center;
  transform: rotate(-180deg);
  color: #009ee2;
  font-weight: bold;
}

.rxEmphasis {
  color: #f39e1a;
  font-weight: bold;
  margin-top: 10px;
}

.boldLable {
  font-weight: bold;
}

@media screen and (max-device-width: 1024px) {
  #capture .btn-primary#printCardBtn {
    display: none !important;
  }

  #capture .btn-primary#downloadCardBtn {
    display: none !important;
  }
}

@media only screen and (max-width: 1023px) {
  .rxDiscountPage {
    background-size: 40% auto;
    padding: 20px 42% 20px 20px;
  }

  #prescriptionDiscountCardComponent h1 {
    font-size: 2em;
  }
}

@media only screen and (max-width: 599px) {
  .rxDiscountPage {
    background-size: 480px auto;
    padding: 20px 20px 600px 20px;
    background-position: right bottom;
  }

  #prescriptionDiscountCardComponent h1 {
    font-size: 2em;
  }
}

@media only screen and (max-width: 519px) {
  #capture {
    padding: 0;
  }

  .prescriptionDiscountCard .modal-body {
    padding: 0;
  }

  .prescriptionDiscountCard .cardHolder {
    width: 350px;
    padding: 16px 0;
  }
  .prescriptionDiscountCard .modal-content .rxCardFront,
  .prescriptionDiscountCard .modal-content .rxCardBack {
    width: 350px;
  }
}
