@import '../variables';

.chat-button {
  @media screen and (max-width: 1023px) {
    &:hover {
      background-color: #cadef4;
      transition: background-color 0.25s ease-out;
    }
  }
  button {
    background-color: transparent;
    border: 0px;
    padding: 1px 10px 0 10px;
    height: 48px;
    &:hover {
      background-color: #cadef4;
      transition: background-color 0.25s ease-out;
    }
    @media screen and (max-width: 1023px) {
      padding: 1px 7px 0;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}
